import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import DrawerContext from "../../../Context/drawerContext";
import {
	constructLongDate,
	constructShortDate,
	constructTime,
} from "../../../hooks/dateCreation";
import UserCardSmall from "../../../templates/UserCardSmall";
import DrawerForm from "./ticketDrawerForm";
import UsersAndProjectsContext from "../../../Context/databaseContext";
import { constructPrice } from "../../../hooks/priceCreation";

export default function TicketContent() {
	const { drawerState } = DrawerContext();

	const { dbState } = UsersAndProjectsContext();

	const project = dbState.projects.find(
		(project) => project.uid === drawerState?.project?.uid
	);

	const ticket = project?.tickets.find(
		(ticket) => ticket.uuid === drawerState?.ticket?.uuid
	);

	const {
		assignee,
		createdAt,
		description,
		// fromRequest,
		payment,
		platform,
		projectUid,
		// seeHowWeDidIt,
		title,
		uuid,
		status,
		price,
		eta,
		estimatedTime,
	} = ticket || {};
	console.log(
		"🚀 ~ file: ticketDrawerContent.js:37 ~ TicketContent ~ ticket:",
		ticket
	);

	const { displayName, photoURL } = assignee || {};
	const { payedAt } = payment || {};

	function convertStatus() {
		switch (status) {
		case -1:
			return "Not started";
		case 0:
			return "Developing";
		case 1:
			return "Testing";
		case 2:
			return "Approve";
		case 3:
			return "Deploying";
		case 4:
			return "Completed";
		default:
			return "Pending";
		}
	}

	return (
		<>
			<Stack direction="row" spacing={1}>
				<Typography variant="noneText">Assignee:</Typography>
				<UserCardSmall
					variant="nonetitle"
					displayName={displayName}
					photo={photoURL}
					disabled={true}
				/>
			</Stack>
			<Stack direction="row">
				<Typography variant="noneText">Creation date:&nbsp;</Typography>
				<Typography variant="nonetitle">
					{`${constructShortDate(createdAt?.seconds)} at ${constructTime(
						createdAt?.seconds
					)}`}
				</Typography>
			</Stack>
			<Stack direction="row">
				<Typography variant="noneText">Payment status:</Typography>
				<Typography variant="nonetitle">{payment?.status}</Typography>
			</Stack>
			{payment?.status === "completed" && (
				<ListItem marginLeft="0px" sx={{ marginLeft: "0px" }}>
					<Stack direction="row">
						<Typography variant="noneText">Paid on:&nbsp;</Typography>
						<Typography variant="nonetitle">{`${constructShortDate(
							payedAt?.seconds
						)} at ${constructTime(payedAt?.seconds)}`}</Typography>
					</Stack>
				</ListItem>
			)}
			<hr></hr>
			<Stack direction="row">
				<Typography variant="noneText">Title:</Typography>
				<Typography variant="noneTitle">{title}</Typography>
			</Stack>
			<Stack>
				<Typography variant="noneText">Description:</Typography>
				<Typography className="descriptionBox" variant="nonetitle">
					{description}
				</Typography>
			</Stack>
			<Stack direction="row">
				<Typography variant="noneText">Platform:</Typography>
				<Typography variant="nonetitle">{platform?.name}</Typography>
			</Stack>
			<Stack direction="row">
				<Typography variant="noneText">
					ETA&nbsp;(Estimated Time of Arrival):
				</Typography>
				<Typography variant="nonetitle">
					{constructLongDate(eta?.seconds)}
				</Typography>
			</Stack>
			<Stack direction="row">
				<Typography variant="noneText">Estimated task time:</Typography>
				<Typography variant="nonetitle">
					{estimatedTime}&nbsp;Hour(s)
				</Typography>
			</Stack>
			<Stack direction="row">
				<Typography variant="noneText">price:</Typography>
				<Typography variant="nonetitle">
					{constructPrice(price / 100)}
				</Typography>
			</Stack>
			<Stack direction="row">
				<Typography variant="noneText">Ticket status:</Typography>
				<Typography variant="noneTitle">{convertStatus()}</Typography>
			</Stack>
			<DrawerForm
				projectUid={projectUid}
				ticketUuid={uuid}
				status={status}
				payment={payment}
			/>
		</>
	);
}
