import React from "react";
//! COMPONENT IMPORT
// import { TicketsSidebar } from "./TicketSidebar";
// import { UserAuth } from "../../Context/AuthContext";
// import TicketsGridComponent from "./components/TicketsGridComponent";

//! MUI IMPORTS
import Stack from "@mui/material/Stack";
import TreeItem from "@mui/lab/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { TreeView } from "../../templates/MuiCustomComponents/customizedTreeview";
//! MUI ICONS

//! FIREBASE IMPORTS
import HeaderComponent from "../../templates/headerComponent";
import RequestsAccordion from "./components/requestsAccordion";
import RequestDrawer from "./components/RequestDrawer";

import { TasksContextProvider } from "../../Context/tasksContext";
import Typography from "@mui/material/Typography";
import { constructLongDate, constructTime } from "../../hooks/dateCreation";
import TicketDrawer from "./components/ticketDrawer";
import { projectsSetter } from "../../hooks/utils";
import UsersAndProjectsContext from "../../Context/databaseContext";

export default function Requests() {
	const { dbState } = UsersAndProjectsContext();
	const [projectsByClient, setProjectsByClient] = React.useState({});

	/**
	 * Represents a project.
	 * @typedef {Object} Project
	 * @property {string} uid - The ID of the project.
	 * @property {string} name - The name of the project.
	 * @property {string} description - The description of the project.
	 * @property {string} clientId - The ID of the client that the project belongs to.
	 * @property {Object} createdAt - The creation date of the project.
	 * @property {number} createdAt.seconds - The number of seconds since the Unix epoch.
	 */

	/**
	 * Represents a client and their projects.
	 * @typedef {Object} ClientAndProject
	 * @property {Object} client - The client object provided by firebase.
	 * @property {Array<Project>} projects - The array of projects of the client.
	 */

	/**
	 * Returns a string representation of the client's display name and creation date.
	 * @param {ClientAndProject} clientAndProject - The client and project object.
	 * @param {string} clientAndProject.client.displayName - The display name of the client.
	 * @param {Object} clientAndProject.client.createdAt - The creation date of the client.
	 * @param {number} clientAndProject.client.createdAt.seconds - The number of seconds since the Unix epoch.
	 * @returns {string} A string representation of the client's display name and creation date.
	 */
	function ClientLabel(clientAndProject) {
		const { displayName, createdAt, email } = clientAndProject.client;
		return `Name: ${displayName} - Email: ${email} - creation date: ${constructLongDate(
			createdAt.seconds
		)} at ${constructTime(createdAt.seconds)}`;
	}

	React.useEffect(() => {
		const clientProjects = projectsSetter(dbState.projects, dbState.clients);
		setProjectsByClient(clientProjects);
	}, [dbState.clients, dbState.projects]);

	return (
		<Stack sx={{ width: "100%", padding: 2 }}>
			<HeaderComponent>Requests</HeaderComponent>
			<Stack
				sx={{
					paddingX: 5,
					paddingY: 1,
					backgroundColor: "#E3F0EF",
					borderRadius: "30px",
					marginTop: 1,
				}}
			>
				<Typography>Clients</Typography>
			</Stack>
			<Stack
				spacing={1}
				sx={{
					paddingLeft: 5,
				}}
			>
				<TreeView
					defaultCollapseIcon={<ExpandMoreIcon />}
					defaultExpandIcon={<ChevronRightIcon />}
				>
					{projectsByClient.length > 0 &&
						projectsByClient?.map((clientAndProject) => (
							<Stack key={clientAndProject.client.uid}>
								<TreeItem
									sx={{
										".MuiTreeItem-content": {
											padding: 0,
											paddingTop: 1,
										},
									}}
									nodeId={clientAndProject.client.uid}
									label={ClientLabel(clientAndProject)}
								>
									<Stack
										sx={{
											// all inside in .MuiAccordion-root can be converted to a css class
											"& .MuiAccordion-root": {
												marginY: 1,
												"&.Mui-expanded": {
													borderRadius: "30px 30px 5px 5px",
												},
												"&.MuiAccordion-root.Mui-expanded": {
													margin: 0,
													marginTop: 1,
												},
												"& .MuiAccordionDetails-root": {
													padding: 0,
												},
											},
										}}
									>
										{clientAndProject.projects.length > 0 ? (
											clientAndProject.projects.map((project, index) => (
												<RequestsAccordion key={index} project={project} />
											))
										) : (
											<Typography variant="tos" sx={{ color: "#929292" }}>
												Don&apos;t have projects
											</Typography>
										)}
									</Stack>
								</TreeItem>
							</Stack>
						))}
				</TreeView>
			</Stack>

			<TasksContextProvider>
				<RequestDrawer />
				<TicketDrawer />
			</TasksContextProvider>
		</Stack>
	);
}
