import { getDownloadURL, getStorage, ref } from "firebase/storage";

/**
 * Sorts the projects by the latest request date and groups them by client.
 * @param {Array<project>} projects - Array of projects.
 * @param {Array<client>} clients - Array of clients.
 * @returns {Array<clientsProjects>} - Array of objects containing the client and their projects.
 */
export function projectsSetter(projects, clients) {
	// first we sort the projects by the latest request date
	// and then we group them by client
	// all of this is done in the same function to avoid iterating the projects array multiple times
	// the info is retrived from the dbState context
	// and the result is returned as an array of objects
	if (projects.length === 0) return [];
	if (clients.length === 0) return [];
	const sortedByRequests = projects.sort((a, b) => {
		b.uid === "78fNLhEtxEV8uJZ8oaYo" && console.log(a.requests, b.requests);
		const createdAtA = Math.max(
			...a.requests.map((request) => {
				convertDateStringIsoToDate(request.createdAt);
			})
		);
		const createdAtB = Math.max(
			...b.requests.map((request) => {
				convertDateStringIsoToDate(request.createdAt);
			})
		);
		return createdAtB - createdAtA;
	});

	const projectsByClient = sortedByRequests.reduce((acc, project) => {
		const client = clients.find((client) => client.uid === project.clientUid);
		if (client) {
			if (!acc[client.uid]) {
				acc[client.uid] = {
					client,
					projects: [],
				};
			}
			acc[client.uid].projects.push(project);
		}
		return acc;
	}, {});

	const clientsProjects = Object.values(projectsByClient);

	return clientsProjects;
}

export async function getUrlByRef(reference) {
	const storage = getStorage();
	const storageRef = ref(storage, reference);
	const link = await getDownloadURL(storageRef);
	return link;
}

export function convertDateStringIsoToDate(IsoString) {
	const date = new Date(IsoString);
	const timestamp = date.getTime() / 1000;
	return timestamp || 0;
}
