//! Colors
// Teal: #8EDED9, #285D69, #F3FDFC
// lightBlue: #93B1FF, #94A3CA, #E5E9F4
// Electric Blue: #0056D8, #264471, #F1F7FF
// Dark Blue: #112031, #5F5F5F, #C0C0C0
// Primary gradient 	#8EDED9 #93B1FF #0056D8
// background Gradient #F3FDFC #F5F5F5
export const MyTheme = {
	typography: {
		fontFamily: ["Ulagadi Sans", "sans-serif"].join(","),
		htmlFontSize: 10,
		titles: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "25px",
			color: "#112031",
		},
		noneTitle: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "500",
			fontSize: "1.5rem",
		},
		noneText: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "500",
			fontSize: "1.5rem",
			marginRight: "10px",
			color: "#285D69",

		},
		descriptiveText: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.5rem",
			color: "#285D69",
		},
		tos: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1rem",
			color: "#112031",
		},
		sideBarTextActive: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "500",
			fontSize: "1.4rem",
			color: "#fff",
		},
		calendarMonth: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "2rem",
			color: "#285D69",
		},
		calendarDay: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.4rem",
			color: "#94A3CA",
		},
		calendarSchedule: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "2rem",
			color: "#112031",
		},
		taskTitle: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "2rem",
			color: "##5F5F5F",
		},
		taskDescription: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "200",
			fontSize: "1.4rem",
			color: "#5f5f5f",
		},
		quoteCartTitle: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "500",
			fontSize: "1.4rem",
			color: "#93B1FF",
		},
		quoteCartContent: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.4rem",
			color: "#fff",
		},
		quoteCartPrice: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "500",
			fontSize: "2rem",
			color: "#fff",
		},
		quoteTotal: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "500",
			fontSize: "2rem",
			color: "#285D69",
		},
		quoteTotalValue: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "2rem",
			color: "#5F5F5F",
		},
		myTeamRole: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.4rem",
			color: "#5f5f5f",
		},
		label: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.2rem",
			color: "#112031",
		},
		label2: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.4rem",
			color: "#112031",
			minWidth: "130px",
		},
		buttonShareCredentials: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "200",
			fontSize: "1.5rem",
			color: "#94A3CA",
		},
		buttonShareCredentialsBold: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "500",
			fontSize: "1.5rem",
			color: "#94A3CA",
		},
		NdaTitle: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "2.4rem",
			color: "#000000",
		},
		chatRight: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.5rem",
			color: "#285D69",
			textAlign: "left",
			"& span": {
				"& a": {
					color: "#3074E5",
				},
			},
			"& a": {
				color: "#3074E5",
			},
		},
		chatLeft: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.5rem",
			color: "#264471",
			textAlign: "left",
			padding: "2%",
			"& span": {
				"& a": {
					fontWeight: "500",
					color: "#3074E5",
				},
			},
			"& a": {
				fontWeight: "500",
				color: "#3074E5",
			},
		},
		myAccountMenuTitle: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "2rem",
			color: "#112031",
		},
		myAccountMenuSubtitle: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.5rem",
			color: "#8EDED9",
		},
		myAccountMenuItem: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.5rem",
			color: "#112031",
		},
		chatHeader: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.5rem",
			color: "#fff",
		},
		gridHeaders: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "450",
			fontSize: "1.5rem",
			color: "#285D69",
		},
	},
	palette: {
		teal: {
			main: "#8EDED9",
			secondary: "#285D69",
			background: "#F3FDFC",
			ternary: "#0F4B58",
		},
		lightBlue: {
			main: "#93B1FF",
			secondary: "#94A3CA",
			background: "#E5E9F4",
		},
		electricBlue: {
			main: "#0056D8",
			secondary: "#264471",
			background: "#F1F7FF",
		},
		darkBlue: {
			main: "#112031",
			secondary: "#5F5F5F",
			background: "#C0C0C0",
		},
		primaryGradient: {
			first: "#8EDED9",
			second: "#93B1FF",
			third: "#0056D8",
		},
		backgroundGradient: {
			first: "#F1F7FF",
			second: "#ffffff",
		},
		tealSecondary: {
			main: "#0f4b58",
			secondary: "#285D69",
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				html: { fontSize: "62.5%" },
			},
		},
		MuiLink: {
			variants: [
				{
					props: { variant: "calendarScheduleLink" },
					style: {
						color: "#0056D8",
						fontFamily: "Ulagadi Sans, sans-serif",
						fontWeight: "400",
						fontSize: "14px",
					},
				},
				{
					props: { variant: "newProjectLink" },
					style: {
						"&:hover": {
							cursor: "pointer",
						},
						color: "#94A3CA",
						fontFamily: "Ulagadi Sans, sans-serif",
						fontWeight: "500",
						fontSize: "14px",
					},
				},
			],
		},
		MuiStepper: {
			styleOverrides: {
				root: {},
			},
		},
		MuiButton: {
			defaultProps: {
				disableElevation: true,
			},
			variants: [
				{
					props: {
						variant: "primaryButton",
					},
					style: {
						fontFamily: "Ulagadi Sans, sans-serif",
						fontWeight: "500",
						background: `linear-gradient(91.51deg, #8EDED9 6.33%, #93B1FF 74.55%, #0056D8 108.54%);`,
						borderRadius: "7px",
						color: "#fff",
						fontSize: "16px",
						padding: "4px 16px",
						":hover": {
							//background: "#8EDED9",
							background: `linear-gradient(#8EDED9, #8EDED9);`,
						},
						textTransform: "none",
					},
				},
				{
					props: {
						variant: "selectButton",
					},
					style: {
						fontFamily: "Ulagadi Sans, sans-serif",
						fontWeight: "400",
						background: `#fff`,
						borderRadius: "7px",
						color: "#112031",
						fontSize: "14px",
						border: "1px solid #c2c2c2",
						width: "200px",
						display: "flex",
						justifyContent: "space-between",
						textTransform: "none",
					},
				},
				{
					props: {
						variant: "changeStateButton",
					},
					style: {
						fontFamily: "Ulagadi Sans, sans-serif",
						fontWeight: "500",
						height: "80px",
						textTransform: "none",
						background: "rgba( 255, 255, 255, 0.7 )",
						backdropFilter: "blur( 8.5px )",
						borderRadius: "10px",
						border: "1px solid rgba( 255, 255, 255, 0.18 )",
					},
				},
				{
					props: {
						variant: "defineTicketButton",
					},
					style: {
						fontFamily: "Ulagadi Sans, sans-serif",
						fontWeight: "500",
						fontSize: "12px",
						textTransform: "none",
						backdropFilter: "blur( 8.5px )",
						border: "1px solid rgba( 255, 255, 255, 0.18 )",
						background: "#8EDED9",
						borderRadius: "15px",
						color: "#fff",
					},
				},
				{
					props: {
						variant: "signUpButton",
					},
					style: {
						background: `#fff`,
						color: "#12B8FF",
						boxShadow: "1px 1px 10px rgba(129, 129, 129, 0.13)",
						borderRadius: "6px",
						textTransform: "none",
					},
				},
				{
					props: {
						variant: "shareCreadentialsButton",
					},
					style: {
						background: "transparent",
						textTransform: "none",
						":hover": {
							background: "transparent",
						},
						"&.Mui-disabled": {
							opacity: "1",
						},
					},
				},
				{
					props: { variant: "taskButton" },
					style: {
						":hover": {
							background: "transparent",
						},
						color: "#264471",
						fontSize: "20px",
						fontWeight: "500",
						textTransform: "none",
					},
				},
				{
					props: { variant: "attachmentTaskButton" },
					style: {
						":hover": {
							background: "transparent",
						},
						color: "#285D69",
						fontSize: "14px",
						fontWeight: "500",
						textTransform: "none",
						paddingLeft: "0px",
						paddingTop: "0px",
					},
				},
				{
					props: { variant: "PlatformsBorderButton" },
					style: {
						borderRadius: "50px",
						minWidth: "250px",
						fontFamily: "inherit",
						border: "solid 3px transparent",
						backgroundImage:
							"linear-gradient(rgba(178, 132, 132, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #8edfda, #4f75ff)",
						backgroundOrigin: "border-box",
						backgroundClip: "content-box, border-box, text",
						boxShadow:
							"2px 1000px 1px #fff inset, 4px 5px 2px rgb(239, 239, 239)",
						":hover": {
							boxShadow: "none",
							color: "white",
							".MuiTypography-root": {
								backgroundClip: "text",
								clip: "text",
								color: "white",
							},
						},
						"& .MuiTypography-root": {
							width: "100%",
							padding: "5px 30px",
							fontSize: "1.2rem",
							fontWeight: "500",
							background: "linear-gradient(0deg, #8edfda, #4f75ff)",
							backgroundClip: "text",
							color: "transparent",
						},
						// 	":hover": {
						// 		backgroundClip: "text",
						// 		clip: "text",
						// 		color: "white",
						// 	},
						// },
					},
				},
				{
					props: { variant: "PseudoLinkButton" },
					style: {
						padding: 0,
						border: "none",
						color: "#000",
						fontSize: "1rem",
						background: "transparent",
						textDecoration: "underline",
						width: " 100px",
						":hover": {
							background: "transparent",
							textDecoration: "none",
						},
					},
				},
			],
		},
		MuiPaper: {
			variants: [
				{
					props: { variant: "platformsUncheckedButton" },
					style: {
						borderRadius: "50px",
						backgroundColor: "#fff",
						width: "32px",
						height: "32px",
						boxShadow: "4px 4px 10px #E9E9E9",
					},
				},
				{
					props: { variant: "platformsCheckedButton" },
					style: {
						position: "absolute",
						borderRadius: "50px",
						background: `linear-gradient(141.2deg, #8EDED9 24.23%, #93B1FF 61.32%, #0056D8 104.45%)`,
						width: "60%",
						aspectRatio: "1 / 1",
						top: 0,
						bottom: 0,
						right: 0,
						left: 0,
						margin: "auto",
					},
				},
				{
					props: { variant: "pagePaper" },
					style: {
						display: "flex",
						backgroundColor: "transparent",
						// paddingTop: "5%",
						justifySelf: "center",
						width: "100%",
						maxHeight: "100%",
						overflow: "auto",
						flexDirection: "column",
					},
				},
				{
					props: { variant: "innerContent" },
					style: {
						display: "flex",
						backgroundColor: "transparent",
						justifySelf: "center",
						height: "100%",
						overflow: "visible",
						flexDirection: "column",
						margin: "0 auto",
					},
				},
				{
					props: { variant: "translucidPagePaper" },
					style: {
						backgroundColor: "#ffffffcc",
						backdropFilter: `blur(20px)`,
						placeSelf: "center",
						display: "flex",
						flexDirection: "column",
						height: "97%",
						minWidth: "100%",
						// boxShadow: "8px 8px 20px #c0c0c040",
						// margin: "20px",
					},
				},
				{
					props: { variant: "teamModal" },
					style: {
						backgroundColor: "#ffffffcc",
						backdropFilter: `blur(20px)`,
						// placeSelf: "center",
						display: "grid",
						height: "90vh",
						width: "100%",
						boxShadow: "8px 8px 20px #c0c0c040",
						borderRadius: "15px",
						padding: "20px",
						alignItems: "flex-start",
					},
				},
				{
					props: { variant: "teamMember" },
					style: {
						backgroundColor: "#ffffffcc",
						backdropFilter: `blur(20px)`,
						placeSelf: "center",
						display: "flex",
						flexDirection: "column",
						height: "auto",
						width: "100%",
						boxShadow: "8px 8px 20px #c0c0c040",
						borderRadius: "15px",
						padding: "20px",
					},
				},
			],
		},
		MuiCard: {
			variants: [
				{
					props: { variant: "platforms" },
					style: {
						backgroundColor: "#ffffff99",
						borderRadius: "9px",
						boxShadow: "2px 2px 15px #E9E9E9",
						backdropFilter: `blur(10px)`,
						fontSize: "15px",
						Color: "#112031",
						fontWeight: "500",
					},
				},
			],
		},
		MuiCardMedia: {
			defaultProps: {
				draggable: "false",
			},
		},
		MuiAvatar: {
			defaultProps: {
				imgProps: { draggable: "false", referrerPolicy: "no-referrer" },
			},
			variants: [
				{
					props: { variant: "discussionsImg" },
					style: {
						width: "25px",
						height: " 25px",
						borderRadius: "100px",
						marginRight: "10px",
						marginTop: " 13px",
						marginBottom: "13px",
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "iconAvatar" },
					style: {
						width: "24px",
						height: "24px",
						marginRight: "5px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "loginAdorments" },
					style: {
						width: "12px",
						height: "12px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "iconPlatforms" },
					style: {
						width: "28px",
						height: "28px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "expandAccordionIconTasks" },
					style: {
						width: "32px",
						height: "32px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "iconTask" },
					style: {
						width: "34px",
						height: "34px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "iconCheck" },
					style: {
						width: "30px",
						height: "30px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "iconArrows" },
					style: {
						width: "18px",
						height: "18px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "questionIcon" },
					style: {
						width: "26px",
						height: "26px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "CustomImage" },
					style: {
						height: "200px",
						width: "300px",
						borderRadius: 0,
						aspectRatio: "",
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "cloudIcon" },
					style: {
						width: "28px",
						height: "28px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "closeIcon" },
					style: {
						width: "50px",
						height: "50px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "stepperIcon" },
					style: {
						width: "10px",
						height: "10px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "stepperCompletedIcon" },
					style: {
						width: "15px",
						height: "15px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "chatIcons" },
					style: {
						width: "17px",
						height: "17px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "backArrowIcon" },
					style: {
						width: "21px",
						height: "21px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "checkboxTasks" },
					style: {
						width: "25px",
						height: "25px",
						borderRadius: 0,
						boxShadow: "4px 4px 10px #E9E9E9",
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "iconCheckboxTasks" },
					style: {
						width: "30px",
						height: "30px",
						marginTop: "8px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "sidebarProjectIcon" },
					style: {
						width: "20px",
						height: "20px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "bannerImage" },
					style: {
						width: "150px",
						height: "54px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "modalImage" },
					style: {
						width: "80%",
						height: "80%",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "iconTasks" },
					style: {
						width: "18px",
						height: "18px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "WipAvatar" },
					style: {
						width: "30px",
						height: "30px",
						boxShadow: "4px 4px 10px #E9E9E9",
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "previewImg" },
					style: {
						width: "100%",
						height: "100%",
						aspectRatio: "1/1",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "avatarSmall" },
					style: {
						width: "20px",
						height: "20px",
						aspectRatio: "1/1",
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
			],
		},
		MuiIconButton: {
			variants: [
				{
					props: { variant: "iconVisibility" },
					style: {
						":hover": {
							backgroundColor: "inherit",
						},
					},
				},
			],
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					// inputProps={{
					//        style: {
					// 		padding: 5
					// 	  }
					//    }}
					// size: "41px",
					borderRadius: "9px",
					backdropFilter: "blur(10px)",
					backgroundColor: "#fff",
					"& input + fieldset": {
						border: "1px solid #C0C0C0",
					},
					"& .Mui-error": {
						borderWidth: 2,
					},
					"& input:valid:focus + fieldset": {
						borderColor: "#8EDED9",
					},
					"& ~ p": {
						marginTop: "0px",
					},
					"& .MuiInputBase-input::placeholder": {
						color: "#5F5F5F",
						opacity: 1,
					},
				},
			},
		},
		MuiMenu: {
			variants: [
				{
					props: { variant: "contextMenu" },
					style: {
						"& .MuiPaper-root": {
							borderRadius: 0,
							background: "rgba(17, 32, 49, 0.32)",
							backdropFilter: "blur(2px)",
							padding: "12px 19px 15px 19px",
							"& .MuiList-root": {
								padding: "0px",
								color: "#fff",
								"& .MuiMenuItem-root": {
									paddingLeft: "0px",
									paddingRight: "0px",
									paddingTop: "10px",
									paddingBottom: "10px",
									":hover": {
										backgroundColor: "inherit",
									},
									":nth-of-type(1)": {
										paddingTop: "0px",
										paddingBottom: "10px",
									},
									":nth-last-of-type(1)": {
										paddingTop: "10px",
										paddingBottom: "0px",
									},
								},
							},
							boxShadow: "none",
						},
					},
				},
			],
		},
		MuiDivider: {
			variants: [
				{
					props: { variant: "mainDivider" },
					style: {
						color: "#285D69",
						"&::before, &::after": {
							borderColor: "#285D69",
						},
					},
				},
			],
		},
		MuiList: {
			variants: [
				{
					props: { variant: "weekHeader" },
					style: {
						display: "flex",
						listStyle: "none",
					},
				},
				{
					props: { variant: "dayList" },
					style: {
						display: "flex",
						listStyle: "none",
						flexWrap: "wrap",
						gap: "5px",
					},
				},
				{
					props: { variant: "hourList" },
					style: {
						padding: "0px",
						padddingRight: "20px",
						listStyle: "none",
					},
				},
			],
		},
		MuiListItemButton: {
			variants: [
				{
					props: { variant: "dayItem" },
					style: {
						// backgroundColor: "rgba(255, 255, 255, 0.2)",
						width: "calc(14.2% - 5px)",
						aspectRatio: "1/1",
						boxSizing: "border-box",
						textAlign: "center",
						borderRadius: "10px",
						boxShadow: "8px 8px 20px #c0c0c040",
						justifyContent: "flex-end",
						alignItems: "flex-end",
						paddingBottom: "5px",
						paddingRight: "5px",
						backgroundColor: "#fff",
						"& .MuiTypography-root": {
							color: "#112031",
						},
						"&.Mui-disabled": {
							backgroundColor: "#f8fafe",
							opacity: 1,
							"& .Mui-selected": {
								backgroundColor: "#f8fafe",
							},
							"& 	.MuiTypography-root": {
								color: "#264471",
							},
						},
						"&.Mui-selected": {
							backgroundColor: "#C8D4F3",
							"& 	.MuiTypography-root": {
								color: "#112031",
							},
							":hover": {
								backgroundColor: "#C8D4F3",
							},
						},
						":hover": {
							backgroundColor: "inherit",
						},
					},
				},
				{
					props: { variant: "hourItem" },
					style: {
						padding: "1vh 10px",
						boxSizing: "border-box",
						borderRadius: "15px",
						background: "#fff",
						marginBottom: "10px",
						boxShadow: "8px 8px 20px rgba(192, 192, 192, 0.25)",
						"& .MuiTypography-root": {
							color: "#112031",
						},
						":hover": {
							backgroundColor: "#EEF3FF",
						},
						"&.Mui-selected": {
							backgroundColor: "#C8D4F3",
							":hover": {
								backgroundColor: "#C8D4F3",
							},
						},
					},
				},
			],
		},
		MuiListItem: {
			variants: [
				{
					props: { variant: "weekHeaderItem" },
					style: {
						width: "14.2%",
					},
				},
			],
		},
		MuiMenuItem: {
			variants: [
				{
					props: { variant: "myAccountMenuItem" },
					style: {
						fontFamily: "Ulagadi Sans, sans-serif",
						fontWeight: "400",
						fontSize: "15px",
						color: "#112031",
					},
				},
			],
		},
		MuiFormControlLabel: {
			variants: [
				{
					props: { variant: "quoteCheckboxLabel" },
					style: {
						"& .MuiFormControlLabel-label": {
							fontFamily: "Ulagadi Sans, sans-serif",
							fontWeight: "500",
							fontSize: "20px",
							color: "#fff",
						},
					},
				},
			],
		},
		MuiPopover: {
			variants: [
				{
					props: { variant: "modalPopoverPaper" },
					style: {
						"& .MuiPopover-paper": {
							backgroundColor: "#ffffffFF",
							backdropFilter: `blur(20px)`,
							// placeSelf: "center",
							display: "grid",
							// maxHeight: "30vh",
							maxWidth: "100vw",
							boxShadow: "8px 8px 20px #c0c0c040",
							borderRadius: "15px",
							padding: "20px",
							alignItems: "flex-start",
						},
					},
				},
			],
		},
		MuiFormHelperText: {
			defaultProps: {
				style: {
					fontSize: "1rem",
					margin: 0,
				},
			},
		},
	},
};
