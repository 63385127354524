import React from "react";
// import { get, postMethod } from "../Utils/services";


export const useQuestionnaire = () => {
	// const [platformsData, setplatformsData] = useState([]);

	const [platform, setPlatform] = React.useState([]);

	const [question, setQuestion] = React.useState("");

	const [tag, setTag] = React.useState("");

	const [type] = React.useState("Closed");

	const [inputOptions, setInputOptions] = React.useState([
		{ option: "", nextQuestion: "" },
	]);

	const platformsArr = [
		"ios",
		"android",
		"flutter",
		"unity",
		"wordpress",
		"shopify",
		"htmlcss",
		"aws-google-do",
		"php",
		"java",
		"integrations",
		"everything",
	];

	const handleChange = (event) => {
		const {
			target: { value },
		} = event;
		setPlatform(typeof value === "string" ? value.split(",") : value);
	};

	const handleRemoveElement = () => {
		setInputOptions(inputOptions.slice(0, -1));
	};

	const handleAddQuestion = async () => {
		const questionObj = {
			platform: platform,
			question: question,
			tag: tag,
			options: inputOptions,
		};

		const settings = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(questionObj),
		};
		try {
			const fetchResponse = await fetch(
				`${process.env.REACT_APP_API_URL}/questionnaire/createQuestionnaire`,
				settings
			);
			const data = await fetchResponse.json();
			console.log(data);
		} catch (e) {
			return e;
		}
	};

	const handleInputChange = (index, event) => {
		const { name, value } = event.target;
	  
		setInputOptions(prevOptions => {
		  const updatedOptions = [...prevOptions];
		  updatedOptions[index][name] = name === "order" ? parseInt(value) : value;
		  return updatedOptions;
		});
	  };

	return {
		platform,
		setQuestion,
		setTag,
		type,
		inputOptions,
		setInputOptions,
		platformsArr,
		handleChange,
		handleRemoveElement,
		handleAddQuestion,
		handleInputChange,
	};
};

// export async function getQuesty() {
// 	try {
// 		const res = await get({ route: "user/getDevelopers" });
// 		if (!res.success) {
// 			throw new Error(res.message);
// 		}
// 		return res.data;
// 	} catch (e) {
// 		console.error(e);
// 		throw e;
// 	}
// }

// export async function createTableQuestion(data) {
// 	const { questionName,  order, } =
// 		data;
// 	try {
// 		const res = await postMethod({
// 			route: `useQuestionnaire/createTableQuestion`,
// 			body: {questionName, order,},
// 		});
// 		if (!res.success) {
// 			throw new Error(res.message);
// 		}
// 	} catch (e) {
// 		console.error(e);
// 		throw e;
// 	}
// }
