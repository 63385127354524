import React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { decryptCredentials } from "../../../hooks/projects";
import { enqueueSnackbar } from "notistack";
import { UserAuth } from "../../../Context/AuthContext";

export function SeeCredentials({ platform, handleClose }) {
	const [decrypted, setDecrypted] = React.useState(false);
	const { user } = UserAuth();
	const [decryptedCredentials, setDecryptedCredentials] = React.useState({});
	const { credentials } = platform;

	if (credentials === undefined || credentials === null) {
		enqueueSnackbar("No credentials found", { variant: "error" });
		handleClose();
	}
	if (Object.keys(credentials).length === 0) {
		enqueueSnackbar("No credentials found", { variant: "error" });
		handleClose();
	}

	async function getDecryptedCredentials() {
		try {
			const decryptedCred = await decryptCredentials(
				credentials,
				user.accessToken
			);
			setDecryptedCredentials(decryptedCred);
			setDecrypted(true);
		} catch (error) {
			enqueueSnackbar("Error decrypting credentials", { variant: "error" });
		}
	}

	return (
		<>
			<DialogTitle>SEE CREDENTIALS {platform.name}</DialogTitle>
			<DialogContent>
				<Stack spacing={1}>
					{decrypted ? (
						<Stack>
							<Typography>username</Typography>
							<Typography
								sx={{
									cursor: "pointer",
									":hover": {
										textDecoration: "underline",
									},
								}}
								onClick={() => {
									navigator.clipboard.writeText(decryptedCredentials?.username);
									enqueueSnackbar("Username copied to clipboard", {
										variant: "success",
									});
								}}
							>
								{decryptedCredentials?.username}
							</Typography>
							<Typography>password</Typography>
							<Typography
								sx={{
									cursor: "pointer",
									":hover": {
										textDecoration: "underline",
									},
								}}
								onClick={() => {
									navigator.clipboard.writeText(decryptedCredentials?.password);
									enqueueSnackbar("Password copied to clipboard", {
										variant: "success",
									});
								}}
							>
								{decryptedCredentials?.password}
							</Typography>
						</Stack>
					) : (
						<Button onClick={async () => await getDecryptedCredentials()}>
							See credentials
						</Button>
					)}
				</Stack>
			</DialogContent>
			<DialogActions
				sx={{
					justifyContent: "space-around",
				}}
			>
				<Button onClick={handleClose}>close</Button>
			</DialogActions>
		</>
	);
}
