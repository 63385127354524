import MuiTableHead from "@mui/material/TableHead";
import MuiTableBody from "@mui/material/TableBody";
import MuiTable from "@mui/material/Table";
import { styled } from "@mui/material/styles";

export const TableHead = styled(MuiTableHead)(() => ({
	background: "transparent",
	"& .MuiTableCell-root": {
		color: "#CBCBCB",
		borderBottom: "none",
	},
}));

export const TableBody = styled(MuiTableBody)(({ theme }) => ({
	"& .MuiTableRow-root": {
		backgroundColor: "#E3F0EF",
	},
	" .MuiTableRow-root td": {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		border: "none",
	},
	" .MuiTableRow-root td:first-of-type": {
		borderRadius: "30px 0px 0px 30px",
	},
	" .MuiTableRow-root td:last-of-type": {
		borderRadius: "0px 30px 30px 0px",
	},
	"& .MuiTableCell-root": {
		paddingTop: 0,
		paddingBottom: 0,
		color: "#49807A",
	},
}));

export const Table = styled(MuiTable)(() => ({
	"&.MuiTable-root": {
		borderCollapse: "separate",
		borderSpacing: "0 10px",
		border: "transparent",
	},
}));
