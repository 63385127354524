//! FIREBASE INITIALIZATION
import { initializeApp } from "firebase/app";

//! FIREBASE GOOGLE AUTH IMPORTS
import { GoogleAuthProvider, getAuth } from "firebase/auth";

//! FIREBASE IMPORTS
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

//! FIREBASE DATABASE
import { getDatabase } from "firebase/database";

//! FIREBASE CONFIG FILE
import firebaseConfig from "./Config/FirebaseConfig";

//* initialitaze firebase
const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const googleProvider = new GoogleAuthProvider();
export const storage = getStorage(app);

export default app;
