// react
import React from "react";
// third-party
import { Routes, Route } from "react-router-dom";

// context
import { NotificationsProvider } from "../templates/NotificationsSnackbar";
import { DiscussionsContextProvider } from "../Context/DiscussionsContext";
import { ChatContextProvider } from "../Context/ChatContext";
import { Questionnaire } from "../components/Questionnaire";

//local imports
import { PrivateRoutes } from "./PrivateRoutes";
import RequestsPage from "../pages/requests";
import RegistrationPage from "../pages/Registration";
import NotFound from "../pages/404";

const Login = React.lazy(() => import("../components/AdminLogin"));
const Tasks = React.lazy(() => import("../components/Tasks"));
// const StandAlone = React.lazy(() => import("../components/StandAlone"));
const Surveys = React.lazy(() => import("../components/Surveys"));
const Sales = React.lazy(() => import("../components/Sales"));
const Platforms = React.lazy(() => import("../components/Platforms"));
const AdminDevelopers = React.lazy(() =>
	import("../components/AdminDevelopers")
);
const Clients = React.lazy(() => import("../components/Clients"));
const LegalDocuments = React.lazy(() => import("../components/LegalDocuments"));
const Chat = React.lazy(() => import("../components/Chat"));
const Projects = React.lazy(() => import("../components/Projects"));
const Tickets = React.lazy(() => import("../components/Tickets"));
const TicketsOld = React.lazy(() => import("../components/Tickets.Old"));

export default function Router() {
	return (
		<NotificationsProvider>
			<ChatContextProvider>
				<DiscussionsContextProvider>
					<React.Suspense fallback={<>SuspenseLoading</>}>
						<Routes>
							<Route path="*" element={<NotFound />} />
							<Route path="/" element={<Login />} />
							<Route path="/login" element={<Login />} />
							<Route path="/register" element={<RegistrationPage />} />
							<Route element={<PrivateRoutes />}>
								<Route path="/projects.Old" element={<Projects />} />
								<Route path="/Chat" element={<Chat />} />
								<Route path="/tickets" element={<Tickets />} />
								<Route path="/ticketsOld" element={<TicketsOld />} />
								<Route path="/requests" element={<RequestsPage />} />
								<Route path="/tasks" element={<Tasks />} />
								<Route path="/clients" element={<Clients />} />
								<Route path="/sales" element={<Sales />} />
								<Route path="/legal-documents" element={<LegalDocuments />} />
								<Route path="/platforms" element={<Platforms />} />
								<Route path="/admin-developers" element={<AdminDevelopers />} />
								<Route path="/surveys" element={<Surveys />} />
								<Route path="/questionnaire" element={<Questionnaire />} />
							</Route>
						</Routes>
					</React.Suspense>
				</DiscussionsContextProvider>
			</ChatContextProvider>
		</NotificationsProvider>
	);
}
