import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material";

export const CustomOutlinedTextfield = styled(TextField)(() => ({
	padding: 0,
	"& .MuiInputBase-input.MuiOutlinedInput-input": {
		padding: "5px 0px",
		fontSize: "1.2rem",
	},
}));

export const CustomfilledTextfield = styled(TextField)(({ theme }) => ({
	padding: 0,
	"& .MuiInputBase-input.MuiFilledInput-input": {
		padding: theme.spacing(0.25, 0.5),
		fontSize: "1.2rem",
	},
	"& .MuiInputBase-root.MuiFilledInput-root": {
		padding: theme.spacing(0, 0.5),
	},
	"& .MuiInputBase-root.MuiFilledInput-root:after": {
		borderBottom: `2px solid ${theme.palette.tealSecondary.main}`,
	},
	"& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)":
		{
			marginRight: "0px",
			marginTop: "0px",
		},
}));

export const CustomMuiButton = styled(Button)(({ theme }) => ({
	padding: theme.spacing(0.5),
	fontSize: "1.2rem",
	backgroundColor: theme.palette.tealSecondary.main,
	color: "#f0fffe",
	"&:hover": {
		backgroundColor: theme.palette.tealSecondary.secondary,
	},
}));

export const CustomDrawer = styled(Drawer)(() => ({
	"& .MuiPaper-root.MuiDrawer-paper": {
		backgroundColor: "#dfedf099",
		minWidth: "35vw",
		maxWidth: "35vw",
		backdropFilter: "blur(5px)",
	},
}));
