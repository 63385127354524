import Avatar from "@mui/material/Avatar";

export default function PlatformVisualizer({ platform, avatarVariant }) {
	// note only use '??' operator when you want to check if the value is null or undefined
	// use || operator when you want to check if the value is null or undefined or empty string
	return (
		<Avatar
			variant={avatarVariant ?? "iconAvatar"}
			src={platform.icons.active}
		/>
	);
}
