import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material";
export default function DrawerContent({ children }) {
	return (
		<Stack
			padding={2}
			sx={{
				minWidth: `calc(100% - ${useTheme().spacing(4)})`,
				maxWidth: `calc(100% - ${useTheme().spacing(4)})`,
				maxHeight: "100%",
				overflowY: "auto",
				overflowX: "clip",
			}}
			spacing={1}
		>
			{children}
		</Stack>
	);
}
