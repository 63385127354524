import { enqueueSnackbar } from "notistack";

import { postMethodAuthed } from "../Utils/services";

/**
 * This function updates the project leader from the given project
 * @param {Object} project - This is the project object that will be updated
 * @param {Object} projectLeader - This is the project leader User object
 * @throws {Error} This function throws an error if the request fails
 * @returns {Promise<boolean>} This function dont return anything
 */
export async function setProjectLeader(project, projectLeader, accessToken) {
	try {
		const res = await postMethodAuthed({
			route: "project/setProjectLeader",
			body: {
				project,
				projectLeader,
			},
			accessToken,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
		// return this to be able to use await, not actually needed
		return res.success;
	} catch (e) {
		console.error(e);
		throw e;
	}
}
/**
 * Sends a task to the specified project.
 * @param {Object} project - The object of the project to send the task to.
 * @param {Object} task - The task to send to the project.
 * @returns {Promise<boolean>} A Promise that resolves to a boolean indicating whether the task was successfully sent.
 * @throws {Error} If the request fails or the response is not successful.
 */
export async function sendTask(project, task, accessToken) {
	try {
		const res = await postMethodAuthed({
			route: "project/sendTask",
			body: {
				project,
				task,
			},
			accessToken,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
		return res.success;
	} catch (e) {
		console.error(e);
		throw e;
	}
}

/**
 * This function updates the tickets of the given project
 * @deprecated This function is deprecated and should not be used.
 * @param {string} project - The name of the project.
 * @param {Array} ticket - An array of tasks for the ticket.
 * @param {string} requestUid - The unique ID of the request.
 * @returns {Promise<boolean>} A Promise that resolves to a boolean indicating whether the ticket was successfully sent.
 */
export async function sendTicket(project, ticket, requestUid, accessToken) {
	try {
		const res = await postMethodAuthed({
			route: "project/sendTicket",
			body: {
				project,
				tasks: ticket,
				requestId: requestUid,
			},
			accessToken,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
		// return this to be able to use await, not actually needed
		enqueueSnackbar("Ticket sended", {
			variant: "success",
		});
		return res.success;
	} catch (e) {
		console.error(e);
		throw e;
	}
}

export async function updateTicketEvidence(refs, project, ticket, accessToken) {
	const res = await postMethodAuthed({
		route: "ticket/updateEvidence",
		body: {
			projectUid: project,
			ticketUuid: ticket,
			evidence: refs,
		},
		accessToken,
	});

	if (!res.success) {
		throw new Error(res.message);
	}
	return res.success;
}

export async function editPlatformSettings(
	project,
	platform,
	requiered,
	accessToken
) {
	const res = await postMethodAuthed({
		route: "project/editPlatformSettings",
		body: {
			project,
			platform,
			requiered,
		},
		accessToken,
	});
	if (!res.success) {
		throw new Error(res.message);
	}
	return res.success;
}

export async function decryptCredentials(credentials, accessToken) {
	const res = await postMethodAuthed({
		route: "project/decryptCredentials",
		body: {
			credentials,
		},
		accessToken,
	});
	if (!res.success) {
		throw new Error(res.message);
	}
	return res.data;
}

export async function updateEta(date, project, ticket, accessToken) {
	const res = await postMethodAuthed({
		route: "ticket/udpateETA",
		body: {
			date,
			ticketUuid: ticket,
			projectUid: project,
		},
		accessToken,
	});
	if (!res.success) {
		throw new Error(res.message);
	}
	return res.data;
}

export async function sendRequestEmail(project, accessToken) {
	try {
		const res = await postMethodAuthed({
			route: "project/sendRequestEmail",
			body: {
				project,
			},
			accessToken,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
		enqueueSnackbar("email sended!", {
			variant: "info",
		});
		return res.success;
	} catch (error) {
		enqueueSnackbar(error.message, {
			variant: "error",
		});
		return false;
	}
}

export async function editQuoteReady(
	projectUid,
	quoteReady,
	accessToken,
	email
) {
	try {
		const res = await postMethodAuthed({
			route: "project/editQuoteReady",
			body: {
				projectUid,
				quoteReady,
				email,
			},
			accessToken,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
		enqueueSnackbar("Quote ready edited!", {
			variant: "info",
		});
		return res.success;
	} catch (error) {
		enqueueSnackbar(error.message, {
			variant: "error",
		});
		return false;
	}
}

export async function deleteEvidence(ref, ticketUuid, projectUid, accessToken) {
	try {
		const res = await postMethodAuthed({
			route: "project/deleteEvidenceImg",
			body: {
				ref,
				ticketUuid,
				projectUid,
			},
			accessToken,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
		return res.success;
	} catch (error) {
		console.error("❌ deleteEvidence ~ error:", error);
	}
}

export async function deleteRequest(projectUid, requestUuid, accessToken) {
	try {
		const res = await postMethodAuthed({
			route: "project/deleteRequest",
			body: {
				projectUid,
				requestUuid,
			},
			accessToken,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
		return res.success;
	} catch (error) {
		console.error("❌ deleteRequest ~ error:", error);
		enqueueSnackbar("something went wrong deleting the request", {
			variant: "error",
		});
	}
}
