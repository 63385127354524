import React from "react";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { enqueueSnackbar } from "notistack";

import { CustomDrawer } from "../../../templates/muiCustomComponents";
import DrawerContext from "../../../Context/drawerContext";
import CloseArrow from "../../../Sources/Icons/Drawer/RightArrow.svg";
import DrawerContent from "../../../templates/DrawerContent";
import DrawerButtonAndContent from "../../../templates/DrawerButtonAndContent";
import TicketContent from "./ticketDrawerContent";
import MediaList from "../../ReusableComponents/mediaLIst";

import { updateTicketEvidence } from "../../../hooks/projects";
import UsersAndProjectsContext from "../../../Context/databaseContext";
import { getUrlByRef } from "../../../hooks/utils";
import MediaPrevisualizer from "./mediaViewer";
import { UserAuth } from "../../../Context/AuthContext";
// import { updateTicketEvidence } from "../../../hooks/projects";
// import { enqueueSnackbar } from "notistack";

export default function TicketDrawer() {
	const { dbState } = UsersAndProjectsContext();
	const { user } = UserAuth();
	const { drawerState, closeTicketDrawer } = DrawerContext();

	const mediaListRef = React.useRef();

	const project = dbState?.projects?.find(
		(project) => project?.uid === drawerState?.project?.uid
	);

	const ticket = project?.tickets?.find(
		(ticket) => ticket?.uuid === drawerState?.ticket?.uuid
	);

	async function afterUpload(refs) {
		try {
			await updateTicketEvidence(
				refs,
				project.uid,
				ticket.uuid,
				user.accessToken
			);
			mediaListRef.current.reset();
			enqueueSnackbar("Evidence updated", {
				variant: "info",
			});
		} catch (error) {
			console.error(
				"❌ ~ file: ticketDrawer.js:32 ~ afterUpload ~ error:",
				error
			);
			enqueueSnackbar("Error adding evidence to database", {
				variant: "error",
			});
		}
	}

	const [evidencesLinks, setEvidencesLinks] = React.useState([]);
	React.useEffect(() => {
		async function getLinks() {
			const links = await Promise.all(
				(ticket?.evidence || []).map(async (evidence) => {
					console.log(evidence);
					const link = await getUrlByRef(evidence);
					const attachmentRef = evidence;

					return {
						link,
						attachmentRef,
						ticketUuid: ticket.uuid,
						projectUuid: project.uid,
					};
				})
			);
			setEvidencesLinks(links);
		}
		getLinks();
	}, [ticket]);

	return (
		<CustomDrawer
			anchor="right"
			sx={{
				"& .MuiBackdrop-root.MuiModal-backdrop": {
					backgroundColor: "transparent",
				},
			}}
			open={drawerState.openTicketDrawer}
			onClose={closeTicketDrawer}
			elevation={0}
		>
			<DrawerButtonAndContent>
				{/*
				  //* Close drawer button
				 */}
				<IconButton
					sx={{
						padding: 0,
						height: "100%",
						borderRadius: 0,
					}}
					onClick={() => closeTicketDrawer()}
				>
					<Avatar
						variant="loginAdorments"
						src={CloseArrow}
						sx={{
							marginX: 1,
						}}
					/>
				</IconButton>
				<DrawerContent>
					<TicketContent />
					<Stack
						direction="row"
						sx={{
							minWidth: "fit-content",
							maxWidth: "100%",
							minHeight: evidencesLinks.length > 0 ? "200px" : "0px",
						}}
					>
						<MediaPrevisualizer attachments={evidencesLinks} />
					</Stack>
					<MediaList
						ref={mediaListRef}
						route={`projects/${project?.uid}/evidence/${ticket?.uuid}`}
						callback={afterUpload}
					/>
					<Button onClick={() => mediaListRef.current.uploadFiles()}>
						update evidence
					</Button>
				</DrawerContent>
			</DrawerButtonAndContent>
		</CustomDrawer>
	);
}
