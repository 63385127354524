import React from "react";
import { Outlet, Navigate } from "react-router-dom";

import { UserAuth } from "../Context/AuthContext";
import Sidebar from "../components/sidebar";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import ProjectsContext from "../Context/databaseContext";
import { db } from "../firebase";

export function PrivateRoutes() {
	const { user } = UserAuth();
	const { setProjects, setUsers, setClients } = ProjectsContext();

	const collectionRef = collection(db, "projects");
	const clientsRef = collection(db, "clients");
	const orderQuery = query(collectionRef, orderBy("createdAt", "desc"));
	const usersOrderQuery = query(clientsRef, orderBy("createdAt", "desc"));
	const usersRef = collection(db, "users");

	React.useEffect(() => {
		const unsub = onSnapshot(orderQuery, (snapshot) => {
			const data = snapshot.docs.map((doc) => ({
				...doc.data(),
			}));
			setProjects(data);
		});
		const unsubUsers = onSnapshot(usersRef, (snapshot) => {
			const data = snapshot.docs.map((doc) => ({
				...doc.data(),
			}));
			setUsers(data);
		});
		const unsubClients = onSnapshot(usersOrderQuery, (snapshot) => {
			const data = snapshot.docs.map((doc) => ({
				...doc.data(),
			}));
			setClients(data);
		});

		return () => {
			unsub();
			unsubUsers();
			unsubClients();
		};
	}, []);

	if (user === null) {
		return <Navigate to="/login" />;
	}

	if (user !== null && Object.keys(user).length === 0) {
		return <>loading</>;
	}

	if (user !== null && Object.keys(user).length !== 0) {
		return (
			<Sidebar>
				<Outlet />
			</Sidebar>
		);
	}
}
