import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { TableHead } from "../../../templates/MuiCustomComponents/customizedTable";
import UserCardSmall from "../../../templates/UserCardSmall";
import DrawerContext from "../../../Context/drawerContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import EtaColors from "./etaColor";
import { TreeItem, TreeView } from "@mui/lab";
import UsersAndProjectsContext from "../../../Context/databaseContext";

/* import UserCardSmall from "../../templates/UserCardSmall"; */

export default function TicketsTable({ project, request }) {
	const { dbState } = UsersAndProjectsContext();
	const newProject = dbState.projects.find((p) => p.uid === project?.uid);

	const filteredTicketsRT = newProject?.tickets.filter(
		(ticket) => ticket.fromRequest === request?.uuid
	);

	const { openTicketDrawer } = DrawerContext();

	const [requestUuid, setRequestUuid] = React.useState([]);

	React.useEffect(() => {
		return () => {
			setRequestUuid([]);
		};
	}, []);

	function labelSetter() {
		console.log("rerender");
		if (filteredTicketsRT.length === 0) {
			return "no tickets";
		} else if (filteredTicketsRT.length === 1) {
			return "1 ticket";
		} else {
			return `${filteredTicketsRT.length} tickets`;
		}
	}

	function handleToggle(event, nodeIds) {
		setRequestUuid(nodeIds);
	}

	return (
		<TreeView
			defaultCollapseIcon={<ExpandMoreIcon />}
			defaultExpandIcon={<ChevronRightIcon />}
			expanded={requestUuid}
			onNodeToggle={handleToggle}
		>
			<TreeItem
				nodeId={request.uuid}
				label={labelSetter()}
				sx={{
					".MuiTreeItem-content": {
						paddingLeft: "calc(2em + 14px)",
					},
				}}
			>
				<TableContainer>
					<Table size="small" stickyHeader>
						<TableHead>
							<TableRow
								sx={{
									"& .MuiTableCell-root": {
										background: "transparent",
									},
								}}
							>
								<TableCell>Title</TableCell>
								<TableCell align="left">Description</TableCell>
								<TableCell align="left">Estimated time of Arrival</TableCell>
								<TableCell align="left">Assignee</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{filteredTicketsRT
								?.sort((a, b) => b.createdAt?.seconds - a.createdAt?.seconds)
								.map((ticket, index) => (
									<TableRow
										key={index}
										sx={{
											"&:last-child td, &:last-child th": { border: "none" },
											cursor: "pointer",
											borderRadius: "10px",
											"& .MuiTableCell-root": {
												color: "#555",
											},
											"&:hover": {
												"& .MuiTableCell-root": {
													color: "#49807A",
												},
												"&.MuiTableRow-root": {
													backgroundColor: "#E3F0EF",
													td: {
														":first-of-type": {
															borderRadius: "30px 0px 0px 30px",
														},
														":last-of-type": {
															borderRadius: "0px 30px 30px 0px",
														},
													},
												},
											},
										}}
										onClick={() => openTicketDrawer(project, ticket)}
									>
										<TableCell scope="row">{ticket.title}</TableCell>
										<TableCell align="left">{ticket.description}</TableCell>
										<TableCell align="left">
											<EtaColors ticket={ticket} />
										</TableCell>
										<TableCell align="left">
											<UserCardSmall
												photo={ticket.assignee.photoURL}
												displayName={ticket.assignee.displayName}
											/>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</TreeItem>
		</TreeView>
	);
}
