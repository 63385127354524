import React from "react";

const Context = React.createContext();

/**
 * The initial state for the projects context.
 * @function
 * @param {jsx<component>} children - The children components.
 */
export function UsersAndProjectsContextProvider({ children }) {
	/**
	 * The initial state for the projects context.
	 * @type {ProjectsContextInitialState}
	 */
	/**
	 * The initial state for the projects context.
	 * @typedef {Object} ProjectsContextInitialState
	 * @property {Array<project>} projects - The list of projects.
	 * @property {Array<Object>} users - The list of projects.
	 * @property {Array<Object>} clients - The list of projects.
	 */

	/**
	 *@typedef {Object} project - The list of projects.
	 *@property {number} activeStep - The active step of the project.
	 *@property {string} clientId - The ID of the client that the project belongs to.
	 *@property {string} clientName - The full name of the client that the project belongs to.
	 *@property {Array<Boolean>} completedSteps - Array of completed steps.
	 *@property {Object<createdAtDef>} createdAt - The creation date of the project.
	 *@property {Array<member>} members - All the team members working in the project.
	 *@property {Array<platform>} platforms - The platforms that the project is being developed for.
	 *@property {Object} projectLeader - The project leader, that is responsible for the project.
	 *@property {string} projectName - The name of the project.
	 *@property {string} uid - The uid of the project.
	 *@property {Object<quote>} quote - The quote of the project.
	 *@property {boolean} quoteReady - This prop is only to pass to the quote component in client side, to know if the quote is ready or not.
	 *@property {Array<ticketQuote>} quotes - The list of quotes, that the client has selected to pay.
	 *@property {Array<request>} requests - The list of requests, that the client has made.
	 *@property {Array<ticket>} tickets - The list of tickets, that the admin has created.
	 *@property {Object<updatedAtDef>} updatedAt - The creation date of the project.
	 *@property {string} uid - The uid of the project.
	 *@typedef	{Object} createdAtDef - The creation date of the project.
	 *@property {number} seconds - The number of seconds since the Unix epoch.
	 *@property {number} miliseconds - The number of miliseconds since the Unix epoch.
	 *@typedef	{Object} member - The creation date of the project.
	 *@property {string} displayName - The fullname of the user.
	 *@property {string} email - The email of the member.
	 *@property {string} firstName - The firstName of the member.
	 *@property {string} lastName - The lastName of the member.
	 *@property {string} photoURL - The photoURL of the member.
	 *@property {string} role - The role of the memeber.
	 *@property {string} status - The status of the member.
	 *@property {string} uid - The uid of the member.
	 *@typedef  {Object} platform - The platform that the project is being developed for.|
	 *@property {string} available - The availability of the platform.
	 *@property {string} description - Short description of the platform.
	 *@property {Object<icon>} Icons - The name of the platform.
	 *@property {number} index - The index of the platform.
	 *@property {boolean} isActive - The active state of the platform.
	 *@property {string} name - The name of the platform.
	 *@property {boolean} requested - if we need to request any credentials for the platform.
	 *@property {boolean} sharedCredentials - if we have shared the  credentials for the platform. 
	 *@property {string} uid - The uid of the platform.
	 *@typedef	{Object} icon - The icon of the platform.
	 *@property {Object<String>} active - icon variant with color.
	 *@property {Object<String>} inactive - icon variant without color.
	 *@property {Object<String>} selected - icon blue variant.
	 *@typedef	{Object} quote - The quote of the project.
	 *@property {number} totalPrice - The total amount of the quote.
	 *@property {number} estimatedTime - The estimated time for tickets completion.
	 *@typedef {Object} ticketQuote - A ticket who was selected to be payed.
	 *@property {string} ticketId - The id of the ticket.
	 *@typedef	{Object} updatedAtDef - The creation date of the project.
	 *@property {number} seconds - The number of seconds since the Unix epoch.
	 *@property {number} miliseconds - The number of miliseconds since the Unix epoch.

	 */
	const INITIAL_STATE = {
		projects: [],
		users: [],
		clients: [],
		platforms: [],
	};

	/**
	 * The action object for the setProjects action.
	 * @typedef {Object} SetProjectsAction
	 * @property {string} type - The type of the action.
	 * @property {Array<Object>} payload - The list of projects to set.
	 */

	/**
	 * The action object for the restartState action.
	 * @typedef {Object} RestartStateAction
	 * @property {string} type - The type of the action.
	 */

	/**
	 * The reducer function for the projects context.
	 * @param {ProjectsContextInitialState} state - The current state of the context.
	 * @param {SetProjectsAction|RestartStateAction} action - The action to perform.
	 * @returns {ProjectsContextInitialState} - The new state of the context.
	 */
	function reducer(state, action) {
		switch (action.type) {
		case "SET_PROJECTS":
			return {
				...state,
				projects: action.payload,
			};
		case "SET_USERS":
			return {
				...state,
				users: action.payload,
			};
		case "SET_CLIENTS":
			return {
				...state,
				clients: action.payload,
			};
		case "SET_PLATFORMS":
			return {
				...state,
				platforms: action.payload,
			};
		case "RESTART_STATE":
			return INITIAL_STATE;
		default:
			return state;
		}
	}

	const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);

	/**
	 * Sets the list of projects in the context state.
	 * @function
	 * @param {Array<project>} projects - The list of projects to set.
	 * @returns {Function}
	 */
	function setProjects(projects) {
		dispatch({
			type: "SET_PROJECTS",
			payload: projects,
		});
	}

	function setUsers(users) {
		dispatch({
			type: "SET_USERS",
			payload: users,
		});
	}
	function setClients(clients) {
		dispatch({
			type: "SET_CLIENTS",
			payload: clients,
		});
	}
	function setPlatforms(platforms) {
		dispatch({
			type: "SET_PLATFORMS",
			payload: platforms,
		});
	}

	/**
	 * The context object for the projects context.
	 * @typedef {Object} dbState
	 * @property {Array<project>} projects - The list of projects in the context state.
	 * @property {Array<member>} users - The list of users in the context state.
	 * @property {Array<client>} clients - The list of clients in the context state.
	 * @returns {dbState} - The current state of the context.
	 * @returns {setProjects(): void} - The setProjects function.
	 * @returns {setUsers(): void} - The setProjects function.
	 * @returns {setClients(): void} - The setProjects function.
	 */
	return (
		<Context.Provider
			value={{
				dbState: state,
				setProjects,
				setUsers,
				setClients,
				setPlatforms,
			}}
		>
			{children}
		</Context.Provider>
	);
}

/**
 * The consumer hook for the projects context.
 * @returns {Object} - The projects context state and setProjects function.
 */
/**
 * @typedef {Object} ProjectsContextConsumerHook
 * @property {state<INITIAL_STATE>} dbState - The current state of the context.
 * @property {function} setProjects - Sets the list of projects in the context state.
 * @property {function} setUsers - Sets the list of users in the context state.
 * @property {function} setClients - Sets the list of clients in the context state.
 */
/**
 *@function
 *@returns {ProjectsContextConsumerHook} - The projects context state and setProjects function.
 */
export default function UsersAndProjectsContext() {
	return React.useContext(Context);
}
