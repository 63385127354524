import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";

import BannerIcon from "../../Sources/Icons/AdvanteBannerImg-2.svg";
import { CustomOutlinedTextfield } from "../../templates/muiCustomComponents";

import { useFormik } from "formik";
import * as yup from "yup";

import userInput from "../../Sources/Icons/userInput.svg";
import emailIcon from "../../Sources/Icons/emailIcon.svg";
import keyIcon from "../../Sources/Icons/keyIcon.svg";
import { UserAuth } from "../../Context/AuthContext";
// import keyIcon from "../../Sources/Icons/keyIcon.svg";

export default function Registration() {
	const { createAccount } = UserAuth();
	const validationSchema = yup.object({
		firstName: yup
			.string("Enter your first name")
			.min(1, "First name should be of minimum 1 character length")
			.required("First Name is required"),
		lastName: yup
			.string("enter your last name")
			.min(1, "Last name should be of m minimum 1 character length")
			.required("Last name is required"),
		email: yup
			.string("Enter your email")
			.email("Enter a valid email")
			.required("Email is required"),
		password: yup
			.string("Enter your password")
			.min(6, "Password should be of minimum 6 characters length")
			.required("Password is required"),
		passwordConfirmation: yup
			.string()
			.oneOf([yup.ref("password"), null], "Passwords must match")
			.required("Password is required"),
	});

	const formik = useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
			email: "",
			password: "",
			passwordConfirmation: "",
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			console.log("values", values);
			await createAccount(values.email, values.password, values);
		},
	});

	return (
		<Box
			sx={{
				display: "grid",
				width: "100%",
				height: "100vh",
				background: "#0f4b58",
			}}
		>
			<Stack
				component="form"
				sx={{
					placeSelf: "center",
					alignItems: "center",
					justifyContent: "center",
					margin: "0 auto",
					minWidth: "300px",
					maxWidth: "500px",
				}}
				onSubmit={formik.handleSubmit}
				spacing={2}
			>
				<Avatar variant="bannerImage" src={BannerIcon} />
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<CustomOutlinedTextfield
							name="firstName"
							value={formik.values.firstName}
							onChange={formik.handleChange}
							placeholder="First Name"
							variant="outlined"
							fullWidth
							size="small"
							error={
								formik.touched.firstName && Boolean(formik.errors.firstName)
							}
							helperText={
								(formik.touched.firstName && formik.errors.firstName) || " "
							}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<Avatar variant="loginAdorments" src={userInput} />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<CustomOutlinedTextfield
							name="lastName"
							value={formik.values.lastName}
							onChange={formik.handleChange}
							placeholder="Last Name"
							variant="outlined"
							fullWidth
							size="small"
							error={formik.touched.lastName && Boolean(formik.errors.lastName)}
							helperText={
								(formik.touched.lastName && formik.errors.lastName) || " "
							}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<Avatar variant="loginAdorments" src={userInput} />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<CustomOutlinedTextfield
							name="email"
							value={formik.values.email}
							onChange={formik.handleChange}
							placeholder="Email"
							variant="outlined"
							fullWidth
							size="small"
							error={formik.touched.email && Boolean(formik.errors.email)}
							helperText={(formik.touched.email && formik.errors.email) || " "}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<Avatar variant="loginAdorments" src={emailIcon} />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<CustomOutlinedTextfield
							name="password"
							value={formik.values.password}
							onChange={formik.handleChange}
							placeholder="Password"
							variant="outlined"
							fullWidth
							size="small"
							type="password"
							error={formik.touched.password && Boolean(formik.errors.password)}
							helperText={
								(formik.touched.password && formik.errors.password) || " "
							}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<Avatar variant="loginAdorments" src={keyIcon} />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<CustomOutlinedTextfield
							name="passwordConfirmation"
							value={formik.values.passwordConfirmation}
							onChange={formik.handleChange}
							placeholder="Confirm Password"
							variant="outlined"
							fullWidth
							size="small"
							type="password"
							error={
								formik.touched.passwordConfirmation &&
								Boolean(formik.errors.passwordConfirmation)
							}
							helperText={
								(formik.touched.passwordConfirmation &&
									formik.errors.passwordConfirmation) ||
								" "
							}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<Avatar variant="loginAdorments" src={keyIcon} />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
				</Grid>
				<Button
					variant="primaryButton"
					type="submit"
					sx={{
						width: "300px",
					}}
				>
					Login
				</Button>
			</Stack>
		</Box>
	);
}
