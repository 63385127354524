import React from "react";
import Requests from "../../components/Requests";
import { getPlatformsHook } from "../../hooks/platforms";
import { enqueueSnackbar } from "notistack";
import UsersAndProjectsContext from "../../Context/databaseContext";
import { UserAuth } from "../../Context/AuthContext";

export default function RequestsPage() {
	const { setPlatforms } = UsersAndProjectsContext();
	const { user } = UserAuth();
	async function getPlatforms() {
		try {
			const platforms = await getPlatformsHook(user.accessToken);
			setPlatforms(platforms);
		} catch (error) {
			enqueueSnackbar("Error getting the platforms", { variant: "error" });
		}
	}

	React.useEffect(() => {
		getPlatforms();
	}, []);

	return <Requests />;
}
