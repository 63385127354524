import Paper from "@mui/material/Paper";
import useTheme from "@mui/material/styles/useTheme";

export function MainPage({ children }) {
	const theme = useTheme();
	// const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<Paper
			variant="pagePaper"
			sx={{
				[theme.breakpoints.down("md")]: {
					background: `linear-gradient(180deg, ${theme.palette.backgroundGradient.first} 0%, ${theme.palette.backgroundGradient.second}) 100%`,
					paddingTop: 0,
				},
				[theme.breakpoints.between("md", "lg")]: {
					width: "100%",
				},
			}}
		>
			<Paper
				variant="innerContent"
				sx={{
					width: "100%",
					paddingTop: 8,
					paddingX: 4,
				}}
			>
				{children}
			</Paper>
		</Paper>
	);
}
