import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";

import { convertDateStringIsoToDate } from "../../../hooks/utils";

import RequestRow from "./requestRow";

export default function RequestsTable({ project }) {
	return (
		<>
			<TableContainer>
				<Table>
					<TableHead
						sx={{
							background: "transparent",
							"& .MuiTableCell-root": {
								color: "#CBCBCB",
								borderBottom: "none",
							},
							"& .MuiTableRow-root": {
								marginLeft: "calc(1em + 8px)",
							},
						}}
					>
						<TableRow>
							<TableCell align="left">Description</TableCell>
							<TableCell align="left">Creation Date</TableCell>
							<TableCell align="center">Attachments</TableCell>
							<TableCell align="center">Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{project.requests
							?.sort(
								(a, b) =>
									convertDateStringIsoToDate(b.createdAt) -
									convertDateStringIsoToDate(a.createdAt)
							)
							.filter(
								(request) => !request?.deleted || request.deleted === undefined
							)
							.map((request) => (
								<RequestRow
									key={request.uuid}
									request={request}
									project={project}
								/>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}
