import React from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useTheme } from "@mui/material/styles";

import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { v4 } from "uuid";

import DrawerContext from "../../../Context/drawerContext";

import { CustomOutlinedTextfield } from "../../../templates/muiCustomComponents";

import icon from "../../../Sources/Icons/IconPlatformsPage.svg";
import { sendTask } from "../../../hooks/projects";
import { enqueueSnackbar } from "notistack";
import {
	DesktopDateTimePicker,
	LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Tooltip, Zoom } from "@mui/material";
import UsersAndProjectsContext from "../../../Context/databaseContext";
import { UserAuth } from "../../../Context/AuthContext";

export default function DrawerForm() {
	const { drawerState } = DrawerContext();
	const { dbState } = UsersAndProjectsContext();
	const { user } = UserAuth();

	const [task, setTask] = React.useState({});
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	/* 
        To create Ticket i need 
        - Title
        - Description
        - Platforms
        - Price
        - Deadline
        - Attachments
        - ETA
        - Status
        - Payment
        - Priority
        - Request Uuid
        - assignee


		That is done but i need to do some refactoring and clean up the code
		- i need to crete tickets for each request and add them to the project
		- i need to add the assignee to the project members
		- i need to add the project leader to the project
		- i need to add the ticket to the project tickets and not create a new array, add to the existing one
    */

	/* 	function defaultPlatforms() {
		const platforms = [];

		drawerState.request?.platforms.forEach((platform) => {
			platforms.push(platform.uid);
		});

		return platforms;
	} */

	const validationSchema = yup.object({
		title: yup.string("").required("Ticket title is required"),
		description: yup.string("").required("description is required"),
		estimatedTime: yup
			.number("")
			.typeError("Please enter a valid number")
			.required("Price is required"),
		platform: yup
			.string()
			.required("platform is required")
			.notOneOf(["A"], "Select a valid platform"),
		assignee: yup
			.string()
			.required("assignee is required")
			.notOneOf(["A"], "Select a valid assignee"),
		ETA: yup.date().required("date is requiered"),
	});

	console.log(drawerState?.request?.platforms);

	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
		control,
	} = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			platform: "A",
			assignee: "A",
			ETA: new Date(),
		},
		mode: "onTouched",
	});

	function submitForm(data) {
		const assignee = dbState.users.find(
			(member) => member.uid === data.assignee
		);

		const findedPlatform = dbState.platforms.find(
			(p) => p.uid === data.platform
		);

		const task = { ...data };
		task.assignee = {
			uid: assignee.uid,
			firstName: assignee.firstName,
			lastName: assignee.lastName,
			photoURL: assignee.photoURL,
			userRole: assignee.userRole,
			email: assignee.email,
			displayName: assignee.displayName,
		};
		task.platform = {
			uid: findedPlatform.uid,
			name: findedPlatform.name,
			icon: findedPlatform.icon,
			icons: findedPlatform.icons,
			hourlyCost: findedPlatform.hourlyCost,
			requested: true,
			sharedCredentials: false,
			credentials: {},
		};
		task.price = 0;
		task.uuid = v4();
		task.status = 0;
		task.fromRequest = drawerState.request.uuid;

		setTask(task);
		handleOpen();
	}

	function handleOpen() {
		setOpen(true);
	}

	async function taskSend() {
		try {
			setLoading(true);

			await sendTask(drawerState.project, task, user.accessToken);
			console.log(
				"🚀 ~ file: requestDrawerForm.js:159 ~ taskSend ~ drawerState.project:",
				{ ...drawerState.project }
			);
			console.log("🚀 ~ file: requestDrawerForm.js:159 ~ taskSend ~ task:", {
				...task,
			});
			setLoading(false);
			setTask({});
			reset();
			setOpen(false);
		} catch (e) {
			console.log(e);
			enqueueSnackbar("Error sending task", { variant: "error" });
			setLoading(false);
		}
	}

	const stackProps = {
		spacing: 1,
		direction: "column",
		sx: { width: "100%", maxWidth: "200px" },
	};

	const style = {
		"& .MuiOutlinedInput-root": {
			paddingLeft: "5px",
			marginLeft: 0,
			width: "100%",
		},
	};

	const typographyProps = {
		variant: "tos",
		sx: {
			color: theme.palette.tealSecondary.main,
			paddingTop: "0.6rem",
			fontSize: "1.4rem",
		},
	};

	return (
		<Stack spacing={1}>
			<Stack spacing={1}>
				<Stack direction="row">
					<Stack direction="row" spacing={1} alignItems="center">
						<Avatar variant="backArrowIcon" src={icon} />
						<Typography
							variant="calendarSchedule"
							sx={{
								color: theme.palette.tealSecondary.main,
								fontWeight: "500",
							}}
						>
							Create Task
						</Typography>
					</Stack>
				</Stack>
				<Stack component="form" onSubmit={handleSubmit(submitForm)}>
					<Stack {...stackProps}>
						<Typography {...typographyProps} variant="tos">
							Task title
						</Typography>
						<CustomOutlinedTextfield
							sx={{
								style,
								".MuiInputBase-root.MuiOutlinedInput-root": {
									paddingLeft: "5px",
								},
							}}
							{...register("title")}
							error={errors.title}
							helperText={errors.title ? errors.title?.message : " "}
							placeholder="Task title"
						/>
					</Stack>
					<Stack
						{...stackProps}
						sx={{
							width: "100%",
						}}
					>
						<Typography {...typographyProps} variant="tos">
							Task description
						</Typography>
						<CustomOutlinedTextfield
							{...register("description")}
							placeholder="Task description"
							sx={{
								".MuiInputBase-root.MuiOutlinedInput-root": {
									padding: "0px",
									paddingLeft: "5px",
									maxWidth: "400px",
								},
							}}
							name="description"
							error={errors.description}
							multiline
							rows={4}
							helperText={
								errors.description ? errors.description?.message : " "
							}
						/>
					</Stack>
					<Stack {...stackProps}>
						<Typography {...typographyProps} variant="tos">
							Ticket estimated time (in hours)
						</Typography>
						<CustomOutlinedTextfield
							{...register("estimatedTime")}
							placeholder="Ticket Estimated Time"
							sx={{
								style,
								".MuiInputBase-root.MuiOutlinedInput-root": {
									paddingLeft: "5px",
								},
							}}
							name="estimatedTime"
							error={errors.estimatedTime}
							helperText={
								errors.estimatedTime ? errors.estimatedTime?.message : " "
							}
						/>
					</Stack>
					<Stack {...stackProps}>
						<Typography {...typographyProps} variant="tos">
							Ticket ETA
						</Typography>
						<Controller
							control={control}
							name="ETA"
							render={({ field: { onChange, onBlur, value, ref } }) => (
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DesktopDateTimePicker
										disablePast
										onBlur={onBlur}
										name="ETA"
										value={value}
										onChange={onChange}
										inputRef={ref}
										renderInput={(params) => (
											<CustomOutlinedTextfield
												{...params}
												sx={{ ...style }}
												error={errors.ETA}
												helperText={errors.ETA ? errors.ETA?.message : " "}
											/>
										)}
									/>
								</LocalizationProvider>
							)}
						/>
					</Stack>
					<Stack {...stackProps}>
						<Typography {...typographyProps} variant="tos">
							Platforms
						</Typography>
						<Controller
							control={control}
							name="platform"
							render={({ field: { onChange, onBlur, value, ref } }) => (
								<CustomOutlinedTextfield
									select
									name="platform"
									onChange={onChange}
									onBlur={onBlur}
									value={value}
									inputRef={ref}
									sx={{ ...style, width: "200px" }}
									error={errors.platform}
									helperText={errors.platform ? errors.platform?.message : " "}
									SelectProps={{
										MenuProps: {
											elevation: 0,
											anchorOrigin: {
												vertical: "bottom",
												horizontal: "left",
											},
											transformOrigin: {
												vertical: "top",
												horizontal: "left",
											},
											PaperProps: {
												style: {
													maxWidth: "200px",
													maxHeight: "200px",
												},
											},
										},
									}}
								>
									<MenuItem value="A" sx={{ display: "none" }}>
										Chose one
									</MenuItem>
									{dbState.platforms
										.sort((a, b) => a.index - b.index)
										.map((platform) => (
											<MenuItem key={platform.uid} value={platform.uid}>
												<Tooltip
													placement={"left"}
													arrow
													TransitionComponent={Zoom}
													title={platform.name}
												>
													<Typography
														noWrap
														sx={{
															width: "100%",
															fontSize: "1.2rem",
														}}
													>
														{platform.name}
													</Typography>
												</Tooltip>
											</MenuItem>
										))}
								</CustomOutlinedTextfield>
							)}
						/>
					</Stack>
					<Stack {...stackProps}>
						<Typography {...typographyProps} variant="tos">
							Task assignee
						</Typography>
						<Controller
							control={control}
							name="assignee"
							render={({ field: { onChange, onBlur, value, ref } }) => (
								<CustomOutlinedTextfield
									select
									inputRef={ref}
									value={value}
									onChange={onChange}
									onBlur={onBlur}
									sx={{ ...style, width: "200px" }}
									placeholder="Select a task assignee"
									error={errors.assignee}
									helperText={errors.assignee ? errors.assignee?.message : " "}
									displayEmpty
									SelectProps={{
										MenuProps: {
											elevation: 0,
											anchorOrigin: {
												vertical: "bottom",
												horizontal: "left",
											},
											transformOrigin: {
												vertical: "top",
												horizontal: "left",
											},
											PaperProps: {
												style: {
													maxWidth: "200px",
													maxHeight: "200px",
												},
											},
										},
									}}
								>
									<MenuItem value="A" sx={{ display: "none" }}>
										Chose one
									</MenuItem>
									{dbState.users?.map((user) => (
										<MenuItem key={user.documentId} value={user.documentId}>
											<Tooltip
												placement="left"
												arrow
												TransitionComponent={Zoom}
												title={user.displayName}
											>
												<Typography
													noWrap
													sx={{
														// width: "100%",
														// paddingX: 2,
														fontSize: "1.2rem",
													}}
												>
													{user.displayName}
												</Typography>
											</Tooltip>
										</MenuItem>
									))}
								</CustomOutlinedTextfield>
							)}
						/>
					</Stack>
					<Dialog open={open}>
						<DialogTitle>Are you sure?</DialogTitle>
						<DialogContent>
							You cannot edit a ticket after sending
							<Stack spacing={1}>
								<Typography>Title: {task.title}</Typography>
								<Typography>Description: {task.description}</Typography>
								<Typography>
									Aproximated time in hours: {task.estimatedTime}
								</Typography>
								<Stack>
									<Typography>Platform: </Typography>
									<Stack direction="row" spacing={1} width="100%">
										<Chip
											size="small"
											key={task?.platform?.uid}
											label={task?.platform?.name}
											sx={{ maxWidth: "100px" }}
										/>
									</Stack>
								</Stack>
							</Stack>
						</DialogContent>
						<DialogActions
							sx={{
								justifyContent: "space-around",
							}}
						>
							<Button disabled={loading} onClick={() => setOpen(false)}>
								Cancel
							</Button>
							<Button disabled={loading} onClick={() => taskSend()}>
								Send
							</Button>
						</DialogActions>
					</Dialog>
					<Button type="submit">Save Task</Button>
				</Stack>
			</Stack>
		</Stack>
	);
}
