import React from "react";
import {
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	signOut,
	onAuthStateChanged,
	updateProfile,
} from "firebase/auth";

import { auth } from "../firebase";
import { createUser, getUserById } from "../hooks/users";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

const UserContext = React.createContext();

export const AuthContextProvider = ({ children }) => {
	const [user, setUser] = React.useState({});
	const navigate = useNavigate();

	async function createAccount(email, pass, data) {
		// Create user with firebase
		const firebaseUser = await createUserWithEmailAndPassword(
			auth,
			email,
			pass
		);
		// Update the profile with the name and photo
		await updateProfile(firebaseUser.user, {
			displayName: `${data.firstName} ${data.lastName}`,
			photoURL: data.photo,
		});
		// userStorage object is the user object that will be stored in the database
		const userStorage = {
			...firebaseUser.user,
			firstName: data.firstName,
			lastName: data.lastName,
			phone: data.phone || "",
			role: "",
			photoURL: "https://pbs.twimg.com/media/FBD79suWEAIq-DI.jpg",
		};
		await createUser(userStorage);
		// Call API to create client in firestore
		// then get the user from the database
		const user = await getUserById(userStorage.uid, userStorage.accessToken);
		Object.assign(user, { accessToken: userStorage.accessToken });
		setUser(user);
	}

	async function login(email, password) {
		try {
			const firebaseUser = await signInWithEmailAndPassword(
				auth,
				email,
				password
			);
			const idTokenResult = await firebaseUser.user
				.getIdTokenResult()
				.then((idTokenResult) => {

					return idTokenResult;
				});
			if (idTokenResult.claims.admin) {
				const user = await getUserById(
					firebaseUser.user.uid,
					firebaseUser.user.accessToken
				);
				Object.assign(user, { accessToken: firebaseUser.user.accessToken });
				setUser(user);
				console.log(user);
				navigate("/requests");
			} else {
				logout();
			}
		} catch (e) {
			console.error(e);
			enqueueSnackbar(e.message, "error");
		}
	}

	function logout() {
		localStorage.clear();
		return signOut(auth);
	}

	async function checkUser(user) {
		if (user === null) {
			setUser(null);
			return;
		}
		const idTokenResult = await user
			.getIdTokenResult()
			.then((idTokenResult) => {
				return idTokenResult;
			});
		if (idTokenResult.claims.admin) {
			Object.assign(user, { accessToken: idTokenResult.token });
			setUser(user);
			return;
		} else {
			setUser(null);
			return;
		}
	}

	React.useEffect(() => {
		const unsuscribe = onAuthStateChanged(auth, (currentUser) => {
			checkUser(currentUser);
		});
		return () => {
			unsuscribe();
		};
	}, []);

	return (
		<UserContext.Provider
			value={{
				user,
				logout,
				login,
				createAccount,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};

export const UserAuth = () => {
	return React.useContext(UserContext);
};
