import api from "../Config/api";

/**
 * Makes a POST request to the specified route with the given body.
 * @param {Object} options - The options for the request.
 * @param {string} options.route - The route to make the request to.
 * @param {Object} options.body - The data to send in the request body.
 * @returns {Promise<Object>} - A Promise that resolves to the response data.
 */
export async function postMethod({ route, body }) {
	// const token = await getAuth().currentUser.accessToken;
	return fetch(`${api.url}/${route}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			// "Authorization": `Bearer ${token}`,
		},
		body: JSON.stringify(body),
	})
		.then((res) => res.json())
		.catch((error) => {
			console.error(error);
			throw error;
		});
}

export async function postMethodAuthed({ route, body, accessToken }) {

	return fetch(`${api.url}/${route}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
		body: JSON.stringify(body),
	})
		.then((res) => res.json())
		.catch((error) => {
			console.error(error);
			throw error;
		});
}

/**
 * Makes a GET request to the specified route with the given ID.
 * @param {Object} options - The options for the request.
 * @param {string} options.route - The route to make the request to.
 * @param {string} options.id - The ID of the resource to retrieve.
 * @returns {Promise<Object>} - A Promise that resolves to the response data.
 */
export async function getById({ route, id }) {
	return fetch(`${api.url}/${route}/${id}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	})
		.then((res) => res.json())
		.catch((error) => {
			console.error(error);
			throw error;
		});
}
/**
 * Makes a GET request to the specified route.
 * @param {Object} options - The options for the request.
 * @param {string} options.route - The route to make the request to.
 * @returns {Promise<Object>} - A Promise that resolves to the response data.
 */
export async function get({ route }) {
	return fetch(`${api.url}/${route}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	})
		.then((res) => res.json())
		.catch((error) => {
			console.error(error);
			throw error;
		});
}

export async function getAuthed({ route, accessToken }) {
	return fetch(`${api.url}/${route}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`,
			"Content-Type": "application/json",
		},
	})
		.then((res) => res.json())
		.catch((error) => {
			console.error(error);
			throw error;
		});
}

export async function getByIdAuthed({ route, id, accessToken }) {
	return fetch(`${api.url}/${route}/${id}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`,
			"Content-Type": "application/json",
		},
	})
		.then((res) => res.json())
		.catch((error) => {
			console.error(error);
			throw error;
		});
}
