import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material";

export const Main = styled("main", {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	overflowY: "auto",
	flexGrow: 1,
	padding: theme.spacing(0),
	transition: theme.transitions.create("margin", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	maxHeight: "100%",
	// marginLeft: `-${drawerwidth}px`,
	marginLeft: `0px`,
	...(open && {
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	}),
}));

export const openedMixin = (theme, drawerwidth) => ({
	width: drawerwidth,
	maxWidth: "375px",
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

export const closedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(7.5)} + 1px)`,
	},
});

export const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, drawerwidth }) => ({
	width: drawerwidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme, drawerwidth),
		"& .MuiDrawer-paper": openedMixin(theme, drawerwidth),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));
