import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

/**
 * Little comoponent to display a header with an icon and a title
 * @component
 * @param {string} icon - The icon to display, must be a valid path to an image, preferably a svg
 * @param {string} children - The title to display
 */
export default function HeaderComponent({ icon, children }) {
	return (
		<Stack direction="row" sx={{ alignItems: "center" }} spacing={1}>
			{icon && <Avatar src={icon} variant="questionIcon" />}
			<Typography variant="NdaTitle" sx={{ fontWeight: "600" }}>
				{children}
			</Typography>
		</Stack>
	);
}
