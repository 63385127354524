import React from "react";
import { UserAuth } from "./AuthContext";

export const DiscussionsContext = React.createContext();

export const DiscussionsContextProvider = ({ children }) => {
	const { user } = UserAuth();
	const INITIAL_STATE = {
		discussionsId: "null",
		user: {},
	};

	const discussionsReducer = (state, action) => {
		switch (action.type) {
		case "CHANGE_USER":
			return {
				user: action.payload,
				discussionsId: action.payload.uid + user.uid,
			};

		default:
			return state;
		}
	};

	const [state, dispatch] = React.useReducer(discussionsReducer, INITIAL_STATE);

	return (
		<DiscussionsContext.Provider value={{ data: state, dispatch }}>
			{children}
		</DiscussionsContext.Provider>
	);
};
