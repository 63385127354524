import React from "react";
import { forwardRef, useImperativeHandle } from "react";
//! MUI IMPORTS
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import UploadIcon from "@mui/icons-material/Upload";
import CircularProgress from "@mui/material/CircularProgress";

import { ref, getStorage, uploadBytesResumable } from "firebase/storage";

//! MUI ICONS
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 } from "uuid";
import { enqueueSnackbar } from "notistack";
import { Tooltip, Typography } from "@mui/material";
// import { updateTicketEvidence } from "../../hooks/projects";

const MediaList = forwardRef(({ route, callback }, parent) => {
	useImperativeHandle(parent, () => ({
		uploadFiles() {
			uploadFiles();
		},
		reset() {
			reset();
		},
		fileList: data.fileList,
		fileLinks: data.fileLinks,
	}));

	const storage = getStorage();
	// const [isLoading, setIsLoading] = React.useState();

	const reducer = (state, action) => {
		switch (action.type) {
		case "SET_DROP_DEPTH":
			return { ...state, dropDepth: action.dropDepth };
		case "SET_IN_DROP_ZONE":
			return { ...state, inDropZone: action.inDropZone };
		case "ADD_FILE_TO_LIST":
			console.log(action);
			return { ...state, fileList: state.fileList.concat(action.files) };
		case "REMOVE_FILE_TO_LIST":
			return { ...state, fileList: action.files };
		case "UPDATE_PROGRESS":
			return { ...state, fileList: action.files };
		case "ADD_FILE_LINK": {
			console.log(action);
			console.log(state);
			const newFileLinks = [...state.fileLinks];
			newFileLinks.push(action.file);
			return {
				...state,
				fileLinks: newFileLinks,
			};
		}
		case "RESET":
			return { ...state, fileList: [], fileLinks: [] };
		default:
			return state;
		}
	};

	const [data, dispatch] = React.useReducer(reducer, {
		dropDepth: 0,
		inDropZone: false,
		fileList: [],
		fileLinks: [],
	});

	function handleDragEnter(e) {
		e.preventDefault();
		e.stopPropagation();

		dispatch({ type: "SET_DROP_DEPTH", dropDepth: data.dropDepth - 1 });
		if (data.dropDepth > 0) return;
		dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
	}

	function handleDragOver(e) {
		e.preventDefault();
		e.stopPropagation();

		e.dataTransfer.dropEffect = "copy";
		dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: true });
	}

	function handleDrop(e) {
		e.preventDefault();
		e.stopPropagation();

		let files = [...e.dataTransfer.files];
		console.log(files[0]);
		if (files && files.length > 0) {
			const existingFiles = data.fileList.map((f) => f.name);
			files = files.filter((f) => !existingFiles.includes(f.name));
			const newFiles = [];
			files.forEach((file) => {
				newFiles.push({
					file,
					metadata: { progress: 0, error: false, uuid: v4() },
				});
			});

			dispatch({ type: "ADD_FILE_TO_LIST", files: newFiles });
			dispatch({ type: "SET_DROP_DEPTH", dropDepth: 0 });
			dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });

		}
	}

	function removeFile(idx) {
		let files = data.fileList;
		files = files.filter((item, index) => index !== idx.index);

		dispatch({ type: "REMOVE_FILE_TO_LIST", files });
	}

	function reset() {
		dispatch({ type: "RESET" });
	}

	async function uploadFiles() {
		try {
			console.log("uploading");
			const promises = [];
			const filesRefs = [];

			data.fileList.forEach((file) => {
				const storageRef = ref(
					storage,
					`/${route}/${file.metadata.uuid}-${file.file.name}`
				);
				const uploadTask = uploadBytesResumable(storageRef, file.file);
				promises.push(uploadTask);
				uploadTask.on(
					"state_changed",
					(snapshot) => {
						const progressObj =
							(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						console.log("Upload is " + progressObj + "% done");

						const filesCopy = [...data.fileList];
						const findedFile = filesCopy.find(
							(fle) => fle.metadata.uuid === file.metadata.uuid
						);
						findedFile.metadata.progress = progressObj;
						filesCopy.map((fle) => {
							if (fle.metadata.uuid === findedFile.metadata.uuid) {
								return findedFile;
							}
							return fle;
						});

						dispatch({ type: "UPDATE_PROGRESS", files: filesCopy });
						switch (snapshot.state) {
						case "paused":
							break;
						case "running":
							break;
						default:
						}
					},
					(error) => {
						console.error(error);
						//TODO[] Handle Error
					},
					async () => {
						filesRefs.push(`${route}/${file.metadata.uuid}-${file.file.name}`);
					}
				);
			});
			//! DO NOT DELETE THIS LINE, IT LOOKS LIKE DOESN'T DO ANYTHING BUT IS USED TO WAIT FOR ALL PROMISES TO BE RESOLVED
			//! WITHOUT THIS LINE, THE PROMISES ARE NOT RESOLVED AND PROMISESLINKANDMETADATA DONT WORK
			// eslint-disable-next-line no-unused-vars
			const photos = await Promise.all(promises);

			await callback(filesRefs);
		} catch (error) {
			console.log("🚀 ~ file: mediaLIst.js:220 ~ uploadFiles ~ error:", error);
			enqueueSnackbar("Error uploading evidence", { variant: "error" });
		}
	}

	function getIconClass(mimeType) {
		// List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml
		const iconClasses = {
			// Media
			image: "image.png",
			audio: "audio.png",
			video: "video.png",
			// Documents
			"application/pdf": "pdf.png",
			"application/msword": "doc.png",
			"application/vnd.ms-word": "doc.png",
			"application/vnd.oasis.opendocument.text": "doc.png",
			"application/vnd.openxmlformats-officedocument.wordprocessingml":
				"doc.png",
			"application/vnd.ms-excel": "xls.png",
			"application/vnd.openxmlformats-officedocument.spreadsheetml": "xls.png",
			"application/vnd.oasis.opendocument.spreadsheet": "xls.png",
			"application/vnd.ms-powerpoint": "fa-file-powerpoint-o",
			"application/vnd.openxmlformats-officedocument.presentationml":
				"fa-file-powerpoint-o",
			"application/vnd.oasis.opendocument.presentation": "fa-file-powerpoint-o",
			"text/plain": "txt.png",
			"text/html": "html.png",
			"application/json": "json.png",
			// Archives
			"application/gzip": "zip.png",
			"application/zip": "zip.png",
		};

		for (const [text, icon] of Object.entries(iconClasses)) {
			if (mimeType.startsWith(text)) {
				return icon;
			}
		}

		return "generic.png";
	}

	// Create a reference to the hidden file input element
	const hiddenFileInput = React.useRef(null);

	// Programatically click the hidden file input element
	// when the Button component is clicked
	const handleUploadButtonClick = () => {
		hiddenFileInput.current.click();
	};
	// Call a function (passed as a prop from the parent component)
	// to handle the user-selected file
	const handleUpload = (event) => {
		let files = [...event.target.files];
		if (files && files.length > 0) {
			const existingFiles = data.fileList.map((f) => f.name);
			files = files.filter((f) => !existingFiles.includes(f.name));
			const newFiles = [];
			files.forEach((file) => {
				newFiles.push({
					file,
					metadata: {
						progress: 0,
						error: false,
						uuid: v4(),
					},
				});
			});
			dispatch({ type: "ADD_FILE_TO_LIST", files: newFiles });
			dispatch({ type: "SET_DROP_DEPTH", dropDepth: 0 });
			dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
		}
	};

	return (
		<Stack
			sx={{
				position: "relative",
				width: "calc(100% - 2rem)",
				margin: "1rem",
				padding: "1rem",
				backgroundColor: "#EBF5F7",
			}}
			spacing={1}
		>
			<Stack
				onDrop={(e) => handleDrop(e)}
				onDragOver={(e) => handleDragOver(e)}
				onDragEnter={(e) => handleDragEnter(e)}
				// onDragLeave={e => handleDragLeave(e)}
				//className={data.inDropZone ? 'drag-drop-zone inside-drag-area' : 'drag-drop-zone', isLoading ? 'disabledDiv' : ''}
				data={data}
				dispatch={dispatch}
				direction="row"
				sx={{
					width: "100%",
					height: "180px",
					backgroundColor: "white",
					marginTop: "0rem",
					overflowX: "auto",
					overflowY: "hidden",
					borderStyle: data.fileList.length > 0 ? "hidden" : "dashed",
				}}
			>
				<p
					style={{
						display: data.fileList.length > 0 ? "none" : "block",
						margin: "auto",
						color: "black",
					}}
				>
					Drag files here
				</p>
				{data.fileList.map((item, index) => (
					<ImageListItem
						key={item.metadata.uuid}
						sx={{ maxWidth: "300px", position: "relative" }}
						index={index}
					>
						<img
							style={{
								width: "100px",
								height: "auto",
								objectFit: "contain",
							}}
							src={`./mimetypes/${getIconClass(item.file.type)}`}
							alt={item.title}
							draggable={false}
							loading="lazy"
						/>
						<CircularProgress
							sx={{
								position: "absolute",
								left: 0,
								right: 0,
								top: 0,
								bottom: 0,
								margin: "auto",
							}}
							variant="determinate"
							value={item.metadata.progress}
						/>
						<ImageListItemBar
							sx={{
								maxWidth: "100px",
								fontSize: "8px",
								paddingLeft: "1rem",
							}}
							actionPosition="right"
							position="below"
							title={
								<Tooltip title={item.file.name} placement="top" arrow>
									<Typography>{item.file.name}</Typography>
								</Tooltip>
							}
							actionIcon={
								<IconButton
									sx={{ color: "black" }}
									onClick={() => {
										removeFile({ index });
									}}
								>
									<DeleteIcon />
								</IconButton>
							}
						/>
					</ImageListItem>
				))}
			</Stack>

			<Stack
				direction="row"
				spacing={2}
				sx={{
					marginBottom: "1rem",
				}}
			>
				<input
					type="file"
					ref={hiddenFileInput}
					onChange={handleUpload}
					style={{ display: "none" }}
				/>

				<Button
					variant="outlined"
					onClick={handleUploadButtonClick}
					startIcon={<UploadIcon />}
				>
					UPLOAD
				</Button>
			</Stack>
		</Stack>
	);
});

MediaList.displayName = "MediaList";

export default MediaList;
