import {
	get,
	getAuthed,
	postMethodAuthed,
} from "../Utils/services";

export async function getPlatformsHook(accessToken) {
	try {
		const res = await getAuthed({
			route: "platforms/getAdminPlatforms",
			accessToken,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
		return res.platforms;
	} catch (e) {
		console.error(e);
		throw e;
	}
}

export async function addNewPlatform(platform, accessToken) {
	try {
		const res = await postMethodAuthed({
			route: `platforms/createPlatform`,
			body: { platform },
			accessToken,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
		return res.data;
	} catch (e) {
		console.error("❌ ~ file: platforms:26 ~ addNewPlatform ~ e:", e);
		throw e;
	}
}

export async function updatePlatform(platform, accessToken) {
	try {
		const res = await postMethodAuthed({
			route: `platforms/updatePlatform`,
			body: { platform },
			accessToken,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
	} catch (e) {
		console.error("❌ ~ file: platforms:32 ~ updatePlatform ~ e:", e);
		throw e;
	}
}

export async function getEarnedByPlatform(platform) {
	try {
		const res = await get({
			route: `platforms/getEarnedByPlatform/${platform}`,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
		return res.earned;
	} catch (e) {
		console.error(e);
		throw e;
	}
}
