import Router from "./router";
import "./App.css";
import { AuthContextProvider } from "../Context/AuthContext";
import { MyTheme } from "./MyTheme";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { DrawerContextProvider } from "../Context/drawerContext";
import { SnackbarProvider } from "notistack";
import { UsersAndProjectsContextProvider } from "../Context/databaseContext";
const theme = createTheme(MyTheme);

function App() {
	return (
		<BrowserRouter>
			<AuthContextProvider>
				<UsersAndProjectsContextProvider>
					<SnackbarProvider
						autoHideDuration={2500}
						anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
					>
						<ThemeProvider theme={theme}>
							<CssBaseline />
							<DrawerContextProvider>
								<Router />
							</DrawerContextProvider>
						</ThemeProvider>
					</SnackbarProvider>
				</UsersAndProjectsContextProvider>
			</AuthContextProvider>
		</BrowserRouter>
	);
}
export default App;
