import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
// import Button from "@mui/material/Button";

import DrawerContext from "../../../Context/drawerContext";
import TasksContext from "../../../Context/tasksContext";

import { CustomDrawer } from "../../../templates/muiCustomComponents";
import DrawerForm from "./requestDrawerForm";
import CloseArrow from "../../../Sources/Icons/Drawer/RightArrow.svg";

// import TaskPreview from "./taskPrevierw";
import RequestInfo from "./requestInfo";
// import { sendTicket } from "../../hooks/projects";
import DrawerContent from "../../../templates/DrawerContent";
import DrawerButtonAndContent from "../../../templates/DrawerButtonAndContent";
import PlatformsCredentials from "./platformsCredentials";

/**
 * @component
 * @category Requests
 * @subcategory Drawer
 * @description This component renders the drawer that is used to create a new request, it dont use props only context
 * @returns Returns a Drawer component
 */
export default function RequestDrawer() {
	const { drawerState, closeRequestDrawer } = DrawerContext();

	const { restartTasks } = TasksContext();

	/* 	async function sendTaskApi() {
		await sendTicket(
			drawerState.project,
			tasksState.tasks,
			drawerState.request.uuid
		);
	} */

	function handleCloseDrawer() {
		console.log("closeDrawer");
		restartTasks();
		closeRequestDrawer();
	}

	return (
		<CustomDrawer
			anchor="right"
			sx={{
				"& .MuiBackdrop-root.MuiModal-backdrop": {
					backgroundColor: "transparent",
				},
			}}
			open={drawerState.openRequestDrawer}
			onClose={handleCloseDrawer}
			elevation={0}
		>
			<DrawerButtonAndContent>
				<IconButton
					sx={{
						padding: 0,
						height: "100%",
						borderRadius: 0,
					}}
					onClick={() => handleCloseDrawer()}
				>
					<Avatar
						variant="loginAdorments"
						src={CloseArrow}
						sx={{
							marginX: 1,
						}}
					/>
				</IconButton>
				<DrawerContent>
					<RequestInfo />
					<DrawerForm />
					<PlatformsCredentials />
				</DrawerContent>
			</DrawerButtonAndContent>
		</CustomDrawer>
	);
}
