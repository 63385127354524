import React from "react";

import { useLocation, useNavigate } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import QuizIcon from "@mui/icons-material/Quiz";

import IconTickets from "../../Sources/Icons/IconTickts.svg";
// import IconTask from "../../Sources/Icons/IconTask.svg";
// import IconProjects from "../../Sources/Icons/IconProjects.svg";
// import IconClients from "../../Sources/Icons/IconClient.svg";
// import IconSales from "../../Sources/Icons/IconSales.svg";
// import IconLegal from "../../Sources/Icons/IconLegal.svg";
import IconChat from "../../Sources/Icons/IconChat.svg";
// import IconPlatfom from "../../Sources/Icons/IconPlatfom.svg";
// import IconAdminDev from "../../Sources/Icons/IconAdminDev.svg";
import IconHam from "../../Sources/Icons/IconHamburger.svg";
import BannerIcon from "../../Sources/Icons/AdvanteBannerImg-2.svg";
import { Drawer, Main } from "../../templates/MuiCustomComponents/Drawer";

export default function Sidebar({ children }) {
	const theme = useTheme();

	const viewportIsLarge = useMediaQuery(theme.breakpoints.up(2000));

	const CollapseSidebar = useMediaQuery(theme.breakpoints.down(1570));

	const [open, setOpen] = React.useState(true);

	function toggleDrawer() {
		open ? setOpen(false) : setOpen(true);
	}

	React.useEffect(() => {
		CollapseSidebar && setOpen(false);
	}, [CollapseSidebar]);

	const [active, setActive] = React.useState("");
	const navigate = useNavigate();
	const location = useLocation();

	function handleChange(urlItem) {
		// setActive(urlItem.url);
		navigate(urlItem.url);
	}

	const sidebarItems = [
		{
			text: "Chat conversations",
			url: "/Chat",
			icon: <img src={IconChat} alt="" />,
		},
		/* 		
		{
			text: "Admin & Developers",
			url: "/admin-developers",
			icon: <img src={IconAdminDev} alt="" />,
		},
		 */
		{ text: "Surveys", url: "/surveys", icon: <BorderColorIcon /> },

		{ text: "Questionnaire", url: "/questionnaire", icon: <QuizIcon /> },
		{
			text: "requests",
			url: "/requests",
			icon: <img src={IconTickets} />,
		},
	];

	const located = sidebarItems.find((item) => item.url === location.pathname);
	React.useEffect(() => {
		if (located) {
			setActive(located.url);
		}
	}, [located]);

	return (
		<Box
			sx={{
				overflowY: "hidden",
				display: "flex",
				background: "linear-gradient(0deg, #F0FFFE 2.97%, #FFFFFF 61.2%)",
			}}
		>
			<Drawer
				variant="permanent"
				open={open}
				sx={{
					"& .MuiDrawer-paper": {
						backgroundColor: theme.palette.teal.ternary,
						borderRadius: "0px 30px 30px 0px",
					},
					borderRadius: "0px 30px 30px 0px",
				}}
				drawerwidth={viewportIsLarge ? "14%" : "16%"}
			>
				<Paper
					elevation={0}
					sx={{
						backgroundColor: theme.palette.teal.ternary,
						padding: open ? 2.5 : 0,
						paddingLeft: open ? 5 : 0,
						marginBottom: 5,
						paddingTop: 5,
						position: "sticky",
						top: 0,
						zIndex: [theme.zIndex.drawer],
					}}
				>
					<Stack
						direction={open ? "row" : "column"}
						spacing={1}
						sx={{
							justifyContent: "space-between",
							alignItems: open ? "flex-start" : "center",
						}}
					>
						<a href="/" style={{ display: open ? "block" : "contents" }}>
							<Avatar
								variant="bannerImage"
								src={open ? BannerIcon : IconHam}
								sx={{
									width: open ? "150px" : "50%",
									alignSelf: "center",
								}}
							/>
							{open && (
								<Typography
									sx={{
										fontFamily: "Ulagadi Sans",
										fontStyle: "normal",
										fontWeight: 400,
										fontSize: "15px",
										color: "#C6EFF5",
									}}
								>
									Administration Panel
								</Typography>
							)}
						</a>

						<IconButton
							onClick={toggleDrawer}
							sx={{
								height: "40px",
								visibility: CollapseSidebar ? "hidden" : "visible",
							}}
							disabled={CollapseSidebar}
						>
							{open ? (
								<ChevronLeftIcon sx={{ color: "#3A7E8C" }} />
							) : (
								<ChevronRightIcon sx={{ color: "#3A7E8C" }} />
							)}
						</IconButton>
					</Stack>
				</Paper>

				<List sx={{ padding: 0 }}>
					{sidebarItems.map((keyName) => (
						<Tooltip
							key={keyName.text}
							title={!open && keyName.text}
							placement={open ? "top" : "right"}
							arrow
						>
							<ListItem disablePadding>
								<ListItemButton
									key={keyName.url}
									sx={{
										minHeight: 48,
										justifyContent: open ? "initial" : "center",
										color: "white",
										px: open ? 5 : 2.5,
										"&.Mui-selected": {
											"& 	.MuiTypography-root": {
												color: "#FFFFFF",
											},
											"& 	.MuiListItemIcon-root": {
												opacity: 1,
											},
										},
									}}
									onClick={() => handleChange(keyName)}
									selected={active === keyName.url}
								>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: open ? 3 : "auto",
											opacity: "0.3",
											justifyContent: "center",
										}}
									>
										{keyName.icon}
									</ListItemIcon>

									<ListItemText
										primary={keyName.text}
										sx={{
											color: "#3A7E8C",
											opacity: open ? 1 : 0,
											textOverflow: "ellipsis",
										}}
									/>
								</ListItemButton>
							</ListItem>
						</Tooltip>
					))}
				</List>
			</Drawer>
			<Main
				open={open}
				drawerwidth={viewportIsLarge ? "16%" : "19%"}
				sx={{ height: "100vh" }}
			>
				{children}
			</Main>
		</Box>
	);
}
