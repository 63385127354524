import Stack from "@mui/material/Stack";

export default function DrawerButtonAndContent({ children }) {
	return (
		<Stack
			sx={{
				height: "100%",
				minWidth: "100%",
				maxWidth: "100%",
			}}
			direction="row"
		>
			{children}
		</Stack>
	);
}
