import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { LicenseInfo } from "@mui/x-license-pro";

switch (process.env.NODE_ENV) {
case "development":
	console.log("🚧 Running in developing mode");
	console.log(
		"🚧 MUI X License Key: ",
		process.env.REACT_APP_MUI_X_LICENSE_KEY
	);
	LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY ?? "");
	break;
case "test":
	console.log("🚧 Running in testing mode");
	// LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY ?? '');
	break;
case "production":
	console.log("🚧 Running in production mode");
	// console.log("🚧 MUI X License Key: ",process.env.REACT_APP_MUI_X_LICENSE_KEY);
	LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY ?? "");
	break;
default:
	break;
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
