import * as yup from "yup";

import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTheme } from "@emotion/react";

import { setProjectLeader } from "../../../hooks/projects";
import DrawerContext from "../../../Context/drawerContext";
import { enqueueSnackbar } from "notistack";
import { CustomOutlinedTextfield } from "../../../templates/muiCustomComponents";
import ProjectsContext from "../../../Context/databaseContext";
import { UserAuth } from "../../../Context/AuthContext";

export default function RequestInfo() {
	const { drawerState } = DrawerContext();
	const { dbState } = ProjectsContext();

	const { user } = UserAuth();

	/* const defaultProjectLeader = tasksState.users.findIndex(
		(user) => user?.uid === project?.projectLeader?.uid
	); */
	const project = dbState.projects.find(
		(project) => project.uid === drawerState?.project?.uid
	);

	const defaultProjectLeader = project?.projectLeader?.uid || "A";

	const validationSchema = yup.object({
		projectLeader: yup
			.string()
			.required("Project leader is required")
			.notOneOf(["A"], "Select a valid project leader"),
	});
	const {
		control,
		formState: { errors },
		handleSubmit,
	} = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			projectLeader: defaultProjectLeader,
		},
		mode: "onTouched",
	});

	/**
	 * This function is used to send the request to the project leader
	 * @param {Object} data - This is the project leader User object, its managed by react-hook-form
	 * @returns {void} - This function dont return anything
	 */
	async function sendProjectLeader(data) {
		try {
			const ProjectLeader = dbState.users.find(
				(user) => user.uid === data.projectLeader
			);

			await setProjectLeader(
				drawerState.project,
				ProjectLeader,
				user.accessToken
			);
		} catch (error) {
			enqueueSnackbar("Error sending the request", { variant: "error" });
		}
	}

	const theme = useTheme();

	const typographyProps = {
		variant: "tos",
		sx: {
			color: theme.palette.tealSecondary.main,
			fontWeight: "500",
			fontSize: "1.4rem",
		},
	};

	return (
		<Stack spacing={1}>
			<Typography variant="myAccountMenuTitle" sx={{ color: "#0f4b58" }}>
				Request description:
			</Typography>
			<Typography className="reqInfoDescription" {...typographyProps}>
				{drawerState.request?.description}
			</Typography>
			{drawerState.request?.attachments.length > 0 && (
				<Stack
					sx={{
						maxWidth: "100%",
					}}
				>
					<Typography {...typographyProps}>Media</Typography>
					<Stack
						maxWidth="100%"
						direction="row"
						spacing={1}
						sx={{
							overflowX: "auto",
							padding: 1,
						}}
					>
						{drawerState.request.attachments.map((attachment, index) => (
							<CardMedia
								sx={{
									width: "100px",
									objectFit: "contain",
									border: `2px solid ${theme.palette.tealSecondary.main}`,
									borderRadius: "5px",
								}}
								key={index}
								src={attachment.fileUrl}
								component="img"
							/>
						))}
					</Stack>
				</Stack>
			)}
			<Stack
				component="form"
				direction="column"
				onSubmit={handleSubmit(sendProjectLeader)}
			>
				<Typography
					variant="tos"
					{...typographyProps}
					sx={{
						...typographyProps.sx,
						paddingTop: "1rem",
						marginBottom: "5px",
					}}
				>
					Project Leader:
				</Typography>
				<Stack direction="row" alignItems="flex-start" spacing={1}>
					{dbState.users.length > 0 && (
						<Controller
							control={control}
							name="projectLeader"
							render={({ field: { onChange, onBlur, value, ref } }) => (
								<CustomOutlinedTextfield
									select
									sx={{
										"& .MuiOutlinedInput-root": {
											paddingLeft: "5px",
											// marginLeft: theme.spacing(1),
											width: "200px",
										},
										"& .MuiFormHelperText-root": {
											marginLeft: `${theme.spacing(1)} !important`,
										},
									}}
									onChange={onChange}
									onBlur={onBlur}
									value={value}
									inputRef={ref}
									name="projectLeader"
									error={errors.projectLeader}
									helperText={
										errors.projectLeader ? errors.projectLeader?.message : " "
									}
									SelectProps={{
										MenuProps: {
											anchorOrigin: {
												vertical: "bottom",
												horizontal: "left",
											},
											transformOrigin: {
												vertical: "top",
												horizontal: "left",
											},
											PaperProps: {
												style: {
													maxWidth: "200px",
													maxHeight: "200px",
												},
											},
										},
									}}
								>
									<MenuItem value="A">Chose one</MenuItem>
									{dbState.users.map((user) => (
										<MenuItem
											sx={{ maxWidth: "200px" }}
											key={user.uid}
											value={user.uid}
										>
											<Typography
												sx={{
													fontSize: "1.2rem",
												}}
												noWrap
											>
												{user.displayName}
											</Typography>
										</MenuItem>
									))}
								</CustomOutlinedTextfield>
							)}
						/>
					)}
					<Button
						type="submit"
						variant="primaryButton"
						sx={{ fontSize: "1.4rem", width: "200px", marginTop: "5px" }}
					>
						Add project leader
					</Button>
				</Stack>
			</Stack>
		</Stack>
	);
}
