import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";
import UsersAndProjectsContext from "../../../Context/databaseContext";
import DrawerContext from "../../../Context/drawerContext";
import PlatformVisualizer from "../../ReusableComponents/PlatformVisualizer";
import React from "react";
import { SeeCredentials } from "./platformCredentialsDialog";
import {
	editPlatformSettings,
	sendRequestEmail,
} from "../../../hooks/projects";
import { enqueueSnackbar } from "notistack";
import { UserAuth } from "../../../Context/AuthContext";
import { Button } from "@mui/material";

export default function PlatformsCredentials() {
	const { drawerState } = DrawerContext();
	const { dbState } = UsersAndProjectsContext();

	const project = dbState.projects.find(
		(project) => project.uid === drawerState?.project?.uid
	);

	const { user } = UserAuth();
	const [disabled, setDisabled] = React.useState(false);

	const [selectedPlatform, setSelectedPlatform] = React.useState(null);
	const [open, setOpen] = React.useState(false);

	function handleOpen(platform) {
		if (platform.credentials === undefined || platform.credentials === null) {
			enqueueSnackbar("No credentials found", { variant: "error" });
			return;
		}
		if (Object.keys(platform.credentials).length === 0) {
			enqueueSnackbar("No credentials found", { variant: "error" });
			return;
		}
		setOpen(true);
		setSelectedPlatform(platform);
	}

	return (
		<Grid
			container
			spacing={2}
			sx={{ width: "calc(100% - 32px)" }}
			columns={10}
		>
			{console.log(project)}
			{project?.platforms?.map((platform) => (
				<Grid key={platform.uid} item sx={{ aspectRatio: "1/1" }} xs={5}>
					<Dialog
						open={open}
						onClose={() => setOpen(false)}
						PaperProps={{
							elevation: 0,
						}}
					>
						<SeeCredentials
							platform={selectedPlatform}
							handleClose={() => setOpen(false)}
						/>
					</Dialog>
					<Stack
						alignItems="center"
						sx={{
							width: "100%",
							height: "100%",
							padding: 1,
							border: "1px solid #bad9d6",
							borderRadius: "7px",
							boxShadow: "1px 5px #bad9d6",
							justifyContent: "center",
						}}
					>
						<Stack alignItems="center">
							<PlatformVisualizer platform={platform} />
							<Typography variant="tos" textAlign="center" fontSize="1.4rem">
								{platform?.name}
							</Typography>
						</Stack>
						{platform?.requested && (
							<MuiLink
								disabled={Object.keys(platform.credentials).length === 0}
								component="button"
								textAlign="center"
								sx={{
									cursor:
										Object.keys(platform.credentials).length === 0
											? "default"
											: "pointer",

									fontSize: "1rem",
									color:
										Object.keys(platform.credentials).length === 0
											? "#ccc"
											: "#0f4b58",
									marginTop: "5px",
									marginBottom: "5px",
								}}
								underline={
									Object.keys(platform.credentials).length === 0
										? "none"
										: "always"
								}
								onClick={() => handleOpen(platform)}
							>
								see credentials {platform.name}
							</MuiLink>
						)}
						<Button
							variant="contained"
							size="small"
							disabled={disabled}
							sx={{
								fontSize: "1rem",
								background: "#e3f0ef",
								color: "#49807A",
								"&:hover": {
									backgroundColor: "#bad9d6",
								},

								"&.Mui-disabled": {
									animation: "loadingAnimation 10s ease-in infinite",
									background:
										"linear-gradient(90deg, #0000001f 0%,#0000001f 50%, #e3f0ef 50%, #e3f0ef 100%)",
									backgroundSize: "200% 200%",
									"@keyframes loadingAnimation": {
										"0%": {
											backgroundPosition: "0%",
										},
										"100%": {
											backgroundPosition: "100%",
										},
									},
								},
							}}
							onClick={() => {
								setDisabled(true);
								sendRequestEmail(project, user.accessToken).then((res) => {
									if (res === false) setDisabled(false);
								});
								setTimeout(() => {
									setDisabled(false);
								}, 10000);
							}}
						>
							Send request Email
						</Button>
						<Check platform={platform} />
					</Stack>
				</Grid>
			))}
		</Grid>
	);

	function Check({ platform }) {
		const requiered = platform.requested;
		const [disabled, setDisabled] = React.useState(false);
		async function sendChanges(requiered) {
			try {
				setDisabled(true);
				await editPlatformSettings(
					project,
					platform,
					!requiered,
					user.accessToken
				);
				setDisabled(false);
				enqueueSnackbar("Platform settings edited", { variant: "success" });
			} catch (error) {
				enqueueSnackbar("Error editing platform settings", {
					variant: "error",
				});
			}
		}
		return (
			<Stack alignItems="center">
				<FormControlLabel
					sx={{
						"&.MuiFormControlLabel-root": {
							marginLeft: "0px",
							marginRight: "0px",
							".MuiFormControlLabel-label": {
								fontSize: "1rem",
							},
						},
					}}
					control={
						<Checkbox
							disabled={disabled}
							checked={requiered}
							onChange={async () => await sendChanges(requiered)}
							sx={{
								"&.MuiCheckbox-root": {
									padding: "0px",
								},
							}}
						/>
					}
					label="Requiered credentials?"
					labelPlacement="start"
				/>
			</Stack>
		);
	}
}
