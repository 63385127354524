import { styled } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";

export const Accordion = styled(MuiAccordion)(({ theme }) => ({
	"&.MuiAccordion-root": {
		backgroundColor: "transparent",
		"::before": {
			display: "none",
		},
		"& .MuiAccordionDetails-root": {
			backgroundColor: "#bad9d60f",
			// backgroundColor: "rgba(186, 217, 214, 0.12)",
			// backgroundColor: "hotpink",
		},
		"& .MuiAccordionSummary-root": {
			borderRadius: "30px",
			minHeight: "30px",
			color: "#49807A",
			backgroundColor: "#BAD9D6",
			transition: `border-radius ${theme.transitions.duration.short}ms ${theme.transitions.easing.sharp}`,
			"&.MuiAccordionSummary-content": {
				".Mui-expanded": {
					margin: 0,
				},
			},

			"&.Mui-expanded": {
				minHeight: "30px",
				borderRadius: "30px 30px 0px 0px",
			},
		},
		"&.Mui-expanded": {
			margin: theme.spacing(1),
		},
	},
}));
