import React from "react";
import {
	Avatar,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	IconButton,
	Stack,
	TableCell,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import DrawerContext from "../../../Context/drawerContext";

import TicketsTable from "./ticketsTable";
import MediaPrevisualizer from "./mediaViewer";
import DeleteIcon from "@mui/icons-material/Delete";
import Images from "../../../Sources/Icons/PictureIcon.svg";
import { constructLongDate, constructTime } from "../../../hooks/dateCreation";
import { UserAuth } from "../../../Context/AuthContext";
import { deleteRequest } from "../../../hooks/projects";
const CustomToolTip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))({
	[`& .${tooltipClasses.tooltip}`]: {
		maxWidth: 500,
		overflowX: "auto",
	},
});
export default function RequestRow({ request, project }) {
	const { openRequestDrawer } = DrawerContext();

	const { user } = UserAuth();

	const [open, setOpen] = React.useState(false);

	async function handleDeleteRequest(requestUuid) {
		console.log("deleteRequest", requestUuid);
		setOpen(false);
		await deleteRequest(project.uid, requestUuid, user.accessToken);
	}

	return (
		<>
			<TableRow
				key={request.uuid}
				sx={{
					"&:last-child td, &:last-child th": { border: 0 },
					cursor: "pointer",
					"&:hover": {
						backgroundColor: "#F5F5F5",
					},
					"&.MuiTableRow-root": {
						backgroundColor: "#E3F0EF",
						td: {
							marginTop: 1,
							marginBottom: 1,
							border: "none",
							":first-of-type": {
								borderRadius: "30px 0px 0px 30px",
								paddingLeft: "calc(1em + 8px)",
							},
							":last-of-type": {
								borderRadius: "0px 30px 30px 0px",
							},
						},
					},
					"& .MuiTableCell-root": {
						paddingTop: 0,
						paddingBottom: 0,
						color: "#49807A",
					},
				}}
			>
				<TableCell
					align="left"
					sx={{
						maxHeight: "100px",
					}}
					onClick={() => openRequestDrawer(project, request)}
				>
					{request.description}
				</TableCell>
				<TableCell
					align="left"
					onClick={() => openRequestDrawer(project, request)}
				>
					{constructLongDate(new Date(request.createdAt).getTime() / 1000)}
					&nbsp;
					{constructTime(new Date(request.createdAt).getTime() / 1000)}
				</TableCell>
				<TableCell
					align="center"
					onClick={() => openRequestDrawer(project, request)}
				>
					<Stack
						sx={{
							justifyContent: "center",
							alignItems: "center",
						}}
						direction="row"
						spacing={1}
					>
						<CustomToolTip
							arrow
							leaveDelay={200}
							enterDelay={50}
							placement="bottom-start"
							title={
								request.attachments.length > 0 && (
									<MediaPrevisualizer attachments={request.attachments} />
								)
							}
						>
							<Avatar variant="expandAccordionIconTasks" src={Images} />
						</CustomToolTip>
						<Typography>{request.attachments.length}</Typography>
					</Stack>
				</TableCell>
				<TableCell align="center">
					<Stack
						sx={{
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Tooltip arrow title="delete">
							<IconButton
								sx={{ width: "fit-content" }}
								onClick={() => {
									setOpen(true);
								}}
							>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
						<Dialog
							open={open}
							onClose={() => setOpen(false)}
							PaperProps={{
								elevation: 0,
							}}
						>
							<DialogTitle> Are you sure?</DialogTitle>
							<DialogActions>
								<Button onClick={() => handleDeleteRequest(request.uuid)}>
									Delete
								</Button>
								<Button onClick={() => setOpen(false)}>Cancel</Button>
							</DialogActions>
						</Dialog>
					</Stack>
				</TableCell>
			</TableRow>
			<TableRow
				sx={{
					"& .MuiTableCell-root": {
						borderBottom: "none",
					},
				}}
			>
				<TableCell
					colSpan={5}
					sx={{
						paddingX: 0,
						paddingY: 1,
					}}
				>
					<TicketsTable request={request} project={project} />
				</TableCell>
			</TableRow>
		</>
	);
}
