import { getAuthed, getByIdAuthed, postMethodAuthed } from "../Utils/services";

export async function getDevs(accessToken) {
	try {
		const res = await getAuthed({
			route: "user/getDevelopers",
			accessToken,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
		return res.data;
	} catch (e) {
		console.error(e);
		throw e;
	}
}

/**
 * @deprecated
 */
export async function getUsers() {
	try {
		const res = await getAuthed({ route: "user/getUsers", accessToken: "" });
		if (!res.success) {
			throw new Error(res.message);
		}
		return res.data;
	} catch (e) {
		console.error(e);
		throw e;
	}
}

export async function createUser(data) {
	const {
		firstName,
		lastName,
		email,
		phone,
		uid,
		displayName,
		photoURL,
		accessToken,
	} = data;
	try {
		const res = await postMethodAuthed({
			route: `user/createUser`,
			body: { firstName, lastName, email, phone, uid, displayName, photoURL },
			accessToken,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
	} catch (e) {
		console.error(e);
		throw e;
	}
}

export async function getUserById(uid, accessToken) {
	try {
		const res = await getByIdAuthed({
			route: `user/getUserById`,
			id: uid,
			accessToken,
		});
		if (!res.success) {
			throw new Error(res.message);
		}
		return res.data;
	} catch (e) {
		console.error(e);
		throw e;
	}
}
