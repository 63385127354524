import React from "react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CustomOutlinedTextfield } from "../../../templates/muiCustomComponents";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import { postMethodAuthed } from "../../../Utils/services";
import { enqueueSnackbar } from "notistack";
import { UserAuth } from "../../../Context/AuthContext";

export default function DrawerForm({
	projectUid,
	ticketUuid,
	status,
	payment,
}) {
	const validationSchema = yup.object({
		ticketStatus: yup
			.number()
			.required("Is requered")
			.notOneOf([-2], "Select a valid option"),
	});

	const theme = useTheme();

	const { user } = UserAuth();

	const {
		control,
		formState: { errors },
		handleSubmit,
	} = useForm({
		defaultValues: {
			ticketStatus: status,
		},
		resolver: yupResolver(validationSchema),
		mode: "onTouched",
	});

	async function handleTicketStatusUpdate(data) {
		try {
			setDisabled(true);
			await postMethodAuthed({
				route: "ticket/updateTicket",
				body: {
					ticketUuid,
					projectUid,
					status: data.ticketStatus,
				},
				accessToken: user.accessToken,
			});
			setDisabled(false);
			enqueueSnackbar("Ticket status updated", { variant: "success" });
		} catch (error) {
			setDisabled(false);
			console.error(error);
			enqueueSnackbar("Error updating ticket status", { variant: "error" });
		}
	}

	const [disabled, setDisabled] = React.useState(false);

	return (
		<Stack
			component="form"
			onSubmit={handleSubmit(handleTicketStatusUpdate)}
			spacing={1}
			marginTop="20px"
		>
			<Controller
				control={control}
				name="ticketStatus"
				render={({ field: { onChange, onBlur, value, ref } }) => (
					<CustomOutlinedTextfield
						select
						sx={{
							"& .MuiOutlinedInput-root": {
								padding: "3px 8px",
								marginLeft: "0px",
								marginTop: "5px",
								width: "200px",
								fontSize: "1.4rem",
								borderRadius: "7px",
							},
							"& .MuiFormHelperText-root": {
								marginLeft: `${theme.spacing(1)} !important`,
							},
						}}
						onChange={onChange}
						onBlur={onBlur}
						value={value}
						inputRef={ref}
						name="ticketStatus"
						error={errors.ticketStatus}
						helperText={
							errors.ticketStatus ? errors.ticketStatus?.message : " "
						}
						disabled={payment?.status === "pending"}
						SelectProps={{
							MenuProps: {
								anchorOrigin: {
									vertical: "bottom",
									horizontal: "left",
								},
								transformOrigin: {
									vertical: "top",
									horizontal: "left",
								},
								PaperProps: {
									style: {
										maxWidth: "200px",
										maxHeight: "200px",
									},
								},
							},
						}}
					>
						<MenuItem value={-2}>Chose one</MenuItem>
						<MenuItem value={-1}>Not started</MenuItem>
						<MenuItem value={0}>Develop</MenuItem>
						<MenuItem value={1}>Testing</MenuItem>
						<MenuItem value={2}>Approve</MenuItem>
						<MenuItem value={3}>Deployment</MenuItem>
						<MenuItem value={4}>Completed</MenuItem>
					</CustomOutlinedTextfield>
				)}
			/>
			<Button
				type="submit"
				variant="primaryButton"
				disabled={payment?.status === "pending" || disabled}
				sx={{ height: "68%", width: "200px", marginTop: "0px" }}
			>
				Update status
			</Button>
		</Stack>
	);
}
