import React from "react";
import { v4 } from "uuid";

import { getUsers } from "../hooks/users";

const Context = React.createContext();

export function TasksContextProvider({ children }) {
	const INITIAL_STATE = {
		tasks: [],
		users: [],
	};

	function reducer(state, action) {
		switch (action.type) {
		case "ADD_TASK": {
			const tasks = [...state.tasks];
			let task = action.payload;
			const findTask = tasks.find((t) => t.uuid === task.uuid);
			if (findTask) {
				task = findTask;
			} else {
				tasks.push(task);
			}
			return {
				...state,
				tasks: tasks,
			};
		}

		case "REMOVE_TASK": {
			const tasks = [...state.tasks];
			const task = action.payload;
			const newTasks = tasks.filter((t) => t.uuid !== task.uuid);
			return {
				...state,
				tasks: newTasks,
			};
		}

		case "CREATE_TASK": {
			const tasks = [...state.tasks];
			const task = {
				uuid: v4(),
			};
			tasks.push(task);
			return {
				...state,
				tasks: tasks,
			};
		}
		case "RESTART_TASKS": {
			return {
				...state,
				tasks: [],
			};
		}
		case "SET_USERS":
			return {
				...state,
				users: action.payload,
			};

		default:
			return state;
		}
	}

	function addTask(task) {
		dispatch({ type: "ADD_TASK", payload: task });
	}

	function createTask() {
		dispatch({ type: "CREATE_TASK" });
	}

	async function users() {
		try {
			const response = await getUsers();
			dispatch({ type: "SET_USERS", payload: response });
		} catch (error) {
			console.error(error);
		}
	}

	function restartTasks() {
		dispatch({ type: "RESTART_TASKS" });
	}

	const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);

	return (
		<Context.Provider
			value={{
				tasksState: state,
				addTask,
				createTask,
				users,
				restartTasks,
			}}
		>
			{children}
		</Context.Provider>
	);
}

export default function TasksContext() {
	return React.useContext(Context);
}
