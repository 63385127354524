import { useEffect, useState } from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Drawer from "@mui/material/Drawer";
// import Box from "@mui/material/Box";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";s
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";
// import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import IconButton from "@mui/material/IconButton";

import "./Questionnaire.css";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useQuestionnaire } from "../../hooks/useQuestionnaire";
import { MainPage } from "../../templates/MainPage";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../../firebase";
// import { Grid } from "@mui/material";

export const Questionnaire = () => {
	const [, setPermissions] = useState([]);
	const [questions, setAllQuesty] = useState({});
	const [openSidebar, setOpenSidebar] = useState(false);
	const {
		platform,
		setQuestion,
		setTag,
		type,
		inputOptions,
		setInputOptions,
		platformsArr,
		handleChange,
		handleRemoveElement,
		handleAddQuestion,
		handleInputChange,
	} = useQuestionnaire();

	useEffect(() => {
		getQuestions();
		//GET ALL THE PERMISSIONS
		getPermissions();
	}, []);
	async function getPermissions() {
		const permissions = [];
		const q = query(collection(db, "permissions"));
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			permissions.push({ ...doc.data(), id: doc.id });
		});
		setPermissions(await Promise.all(permissions));
	}

	async function getQuestions() {
		const questions = [];
		const q = query(collection(db, "questions"));
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			questions.push({ ...doc.data(), id: doc.id });
		});
		setAllQuesty(questions);
	}
	// const handleDeleteRow = (id) => {
	// 	// Copia el estado actual de las preguntas
	// 	const updatedQuestions = { ...questions };
	  
	// 	// Elimina la fila con el id correspondiente
	// 	delete updatedQuestions[id];
	  
	// 	// Actualiza el estado con las preguntas actualizadas
	// 	setQuestions(updatedQuestions);
	//   };
	
	  

	return (
		<MainPage>
			<div className="questionnaire-container">
				<Grid xs={3} display={"flex"} alignContent={"center"} xsOffset="auto">
					<div>
						<h1 className="questionnaire-title">Question</h1>
					</div>
					<div>
						<button
							className="gradient-button questionnaire-title"
							onClick={() => setOpenSidebar(true)}
						>
							<h3 className="gradient-button-text">Add a new question</h3>
						</button>
						<SideBarPlatforms
							openSidebar={openSidebar}
							setOpenSidebar={setOpenSidebar}
						/>
					</div>
				</Grid>
			</div>
			<Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
				<TableHead>
					<TableRow sx={{ height: "4rem" }}>
						<TableCell align="left">
							<h3 className="table-column-text">Question</h3>
						</TableCell>
						<TableCell align="left">
							<h3 className="table-column-text">Order</h3>
						</TableCell>
						<TableCell align="left">
							<h3 className="table-column-text">Actions</h3>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{Object.keys(questions).length > 0 ? (
						Object.values(questions).sort((a, b) => a.order - b.order).map((question) => (
							<TableRow key={question.id} id="currentRow">
								<TableCell>
									<h3 className="table-cell-text">{question.question}</h3>
								</TableCell>
								<TableCell>
									<h3 className="table-cell-text">{question.order}</h3>
								</TableCell>
								<TableCell>
									<IconButton
										sx={{ width: "30px", height: "30px" }}
										onClick={() => {
											const currentRow = document.getElementById("currentRow");
											if (currentRow) {
											  currentRow.remove();
											}
										}}
									>
										<DeleteOutlineSharpIcon
											sx={{ width: "25px", height: "25px" }}
										/>
									</IconButton>
								</TableCell>
							</TableRow>
						))
					) : (
						<>Loading</>
					)}
				</TableBody>
			</Table>
		</MainPage>
	);

	function SideBarPlatforms({ openSidebar, setOpenSidebar }) {
		function toggleDrawer() {
			setOpenSidebar(false);
		}
		return (
			<div>
				<Drawer anchor="right" open={openSidebar} onClose={toggleDrawer}>
					<button onClick={toggleDrawer} className="btn-sidebar">
						<ArrowRightIcon></ArrowRightIcon>
					</button>
					<div className="questionnaire-container">
						<h1 className="questionnaire-title">Add a new question</h1>
						<h3 className="question-text">
							Enter the following information to add a new question to
							questionnaire
						</h3>
						<div className="input-box">
							<FormControl fullWidth sx={{ border: "none" }}>
								<InputLabel id="demo-simple-select-label">
									Select plaform
								</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									multiple
									value={platform}
									label="Next question"
									onChange={handleChange}
									sx={{
										backgroundColor: "#F8F7F7",
										boxShadow: "none",
										".MuiOutlinedInput-notchedOutline": { border: 0 },
									}}
								>
									{platformsArr.map((name) => (
										<MenuItem key={name} value={name}>
											{name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>

						<div className="input-box">
							<input
								className="questionnaire-input"
								type="text"
								placeholder="Enter question"
								onClick={(event) => setQuestion(event.target.value)}
							/>
						</div>

						<div className="input-box">
							<input
								className="questionnaire-input"
								type="text"
								placeholder="Enter tag (Tags are used to identify the same question across different platforms)"
								onChange={(event) => setTag(event.target.value)}
							/>
						</div>
						{type == "Closed" && (
							<div className="dynamic-div-container">
								<div>
									{inputOptions.map((element, index) => {
										return (
											<div key={inputOptions.indexOf(element)}>
												<h3 className="questionnaire-option-header">{`Option ${
													inputOptions.indexOf(element) + 1
												}`}</h3>
												<div className="input-box">
													<input
														name="option"
														className="questionnaire-input"
														type="text"
														placeholder="Option"
														onChange={(event) =>
															handleInputChange(index, event)
														}
													/>
												</div>
												<div className="input-box">
													<input
														name="order"
														className="questionnaire-input"
														type="text"
														placeholder="Order"
														onChange={(event) =>
															handleInputChange(index, event)
														}
													/>
												</div>
											</div>
										);
									})}
								</div>
								<div className="add-option-button-container">
									{inputOptions.length >> 1 ? (
										<>
											<button
												className="add-option-button"
												onClick={() =>
													setInputOptions([
														...inputOptions,
														{ option: "", nextQuestion: "" },
													])
												}
											>
												<AddIcon
													sx={{
														marginRight: 1.5,
														fontSize: "18px",
														marginBottom: "2px",
													}}
												/>
												Add option
											</button>

											<button
												className="remove-option-button"
												onClick={handleRemoveElement}
											>
												<RemoveIcon
													sx={{
														marginRight: 1.5,
														fontSize: "18px",
														marginBottom: "2px",
													}}
												/>
												Remove
											</button>
										</>
									) : (
										<button
											className="add-option-button-single"
											onClick={() =>
												setInputOptions([
													...inputOptions,
													{ option: "", nextQuestion: "" },
												])
											}
										>
											<AddIcon
												sx={{
													marginRight: 1.5,
													fontSize: "18px",
													marginBottom: "2px",
												}}
											/>
											Add option
										</button>
									)}
								</div>
							</div>
						)}
						<div className="questionnaire-button-container">
							<button
								className="questionnaire-button"
								onClick={() => handleAddQuestion()}
							>
								Add question
							</button>
						</div>
					</div>
				</Drawer>
			</div>
		);
	}
};

// function CreateUserDialog(props) {
// 	const { onClose, refresh, user } = props;
// 	const [setCreating] = useState(false);
// 	const [input, setInput] = useState({});

// 	const handleCreate = async () => {
// 		//TODO[Claudio]: Creacion en crudo de usuario / directo en Firebase
// 		// cambiar en un futuro ¿?
// 		if (
// 			input.firstName == "" ||
// 			input.lastName == "" ||
// 			input.email == "" ||
// 			input.password == ""
// 		) {
// 			alert("Please fill all the fields");
// 			return;
// 		}
// 		setCreating(true);
// 		const id = crypto.randomUUID().replace(/-/g, "");
// 		let datum = {};
// 		try {
// 			// console.log((user == null ? console.log("new ", id) : console.log("update ", user.uid)));
// 			datum = {
// 				authProvider: "manual",
// 				displayName: "" + input.firstName + " " + input.lastName,
// 				email: input.email,
// 				firstName: input.firstName,
// 				lastName: input.lastName,
// 				phoneNumber: input.phoneNumber,
// 				password: input.password,
// 				photoURL: "",
// 				status: input.status ?? "idle",
// 				role: input.role ?? "none",
// 			};

// 			// console.log('------->>>>>',datum);
// 			if (user != null) {
// 				// datum.uid = user.uid;
// 				// const userRef = doc(db, "users", user.uid);
// 				// console.log(userRef);
// 				// console.log('00000',datum);
// 				// await setDoc(userRef, datum,{merge: true});
// 				// const q = query(collection(db, "users"), where("uid", "==", user.uid));
// 				// const querySnapshot = await getDocs(q);
// 				// let document_id = querySnapshot.docs[0].id;
// 				// const docRef = doc(db, "users/" + document_id);
// 				// const updateUser = await updateDoc(docRef, datum);
// 			} else {
// 				const auth = getAuth();
// 				const createUser = await createUserWithEmailAndPassword(
// 					auth,
// 					input.email,
// 					input.password
// 				);
// 				const user_info = createUser.user;
// 				datum.uid = user_info.uid;
// 				await setDoc(doc(db, "users", id), datum);
// 			}
// 		} catch (error) {
// 			console.log("---->>>>>", error);
// 		} finally {
// 			// console.log("generated id: ", id);
// 			// console.log("user: ", user);
// 			// console.log("datum: ", datum);
// 			setCreating(false);
// 			onClose(false);
// 			refresh();
// 		}
// 	};

// 	function handleSelect(event) {
// 		setInput({ ...input, role: event.target.value });
// 	}

// }
