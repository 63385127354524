import React from "react";
import { Skeleton, Typography } from "@mui/material";
import { constructDateTime } from "../../../hooks/dateCreation";

export default function EtaColors({ ticket }) {
	const { createdAt, eta } = ticket;
	const [color, setColor] = React.useState("");

	React.useEffect(() => {
		const changeColor = () => {
			// Get the current time
			const currentTime = new Date();

			// Set the starting date, current time, and deadline
			const startingDate = new Date(createdAt?.seconds * 1000); // Example starting date and time
			const deadline = new Date(eta?.seconds * 1000); // Example deadline date and time

			// Calculate the progress based on the starting date, current time, and deadline
			const totalMs = deadline - startingDate;
			const elapsedMs = currentTime - startingDate;
			const progress = totalMs <= 0 ? 1 : elapsedMs / totalMs;

			// Define the color values for interpolation based on the progress
			let startColor, endColor;
			if (progress <= 0) {
				startColor = { r: 255, g: 255, b: 255 };
				endColor = { r: 255, g: 255, b: 255 };
			} else if (progress < 0.16) {
				startColor = { r: 79, g: 117, b: 255 };
				endColor = { r: 79, g: 117, b: 255 };
			} else if (progress < 0.32) {
				startColor = { r: 114, g: 215, b: 113 };
				endColor = { r: 252, g: 231, b: 0 };
			} else if (progress < 0.48) {
				startColor = { r: 114, g: 215, b: 113 };
				endColor = { r: 252, g: 231, b: 0 };
			} else if (progress < 0.64) {
				startColor = { r: 114, g: 215, b: 113 };
				endColor = { r: 101, g: 213, b: 238 };
			} else if (progress < 0.8) {
				startColor = { r: 252, g: 231, b: 0 };
				endColor = { r: 255, g: 168, b: 50 };
			} else if (progress <= 1) {
				startColor = { r: 255, g: 168, b: 50 };
				endColor = { r: 255, g: 67, b: 135 };
			}

			// Calculate the interpolated color based on the progress
			const interpolatedColor = interpolateColors(
				startColor,
				endColor,
				progress
			);

			// Set the text color
			setColor(
				`rgb(${interpolatedColor.r}, ${interpolatedColor.g}, ${interpolatedColor.b})`
			);
		};

		// Update the text color every second

		const intervalId = setInterval(changeColor, 1000);

		// Clean up the interval on component unmount
		return () => clearInterval(intervalId);
	}, []);

	const interpolateColors = (color1, color2, progress) => {
		if (color1 === undefined || color2 === undefined) {
			return { r: 0, g: 0, b: 0 };
		}

		const interpolateChannel = (channel1, channel2, progress) => {
			return Math.round(channel1 + (channel2 - channel1) * progress);
		};

		const r = interpolateChannel(color1.r, color2.r, progress);
		const g = interpolateChannel(color1.g, color2.g, progress);
		const b = interpolateChannel(color1.b, color2.b, progress);

		return { r, g, b };
	};

	return (
		<>
			{color === "" ? (
				<Skeleton/>
			) : (
				<Typography
					sx={{
						color: color,
					}}
				>
					{constructDateTime(eta?.seconds)}
				</Typography>
			)}
		</>
	);
}
