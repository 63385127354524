/**
 * Constructs a formatted price string from a numeric value, example: 1000 -> $1,000.00.
 * @param {number} price - The price to format.
 * @returns {string} The formatted price string.
 */
export function constructPrice(price) {
	if (!price) return "$0.00";
	return `${price.toLocaleString("en-us", {
		style: "currency",
		currency: "USD",
	})}`;
}
