import { styled } from "@mui/material/styles";
import MuiTreeView from "@mui/lab/TreeView";

export const TreeView = styled(MuiTreeView)(() => ({
	minHeight: "fit-content",
	flexGrow: 1,
	maxWidth: "100%",
	overflowY: "auto",
	"& .MuiCollapse-root.MuiTreeItem-group": {
		marginLeft: "calc(1em + 14px)",
		marginRight: "calc(1em + 14px)",
	},
	"& .MuiTreeItem-content": {
		":hover": {
			backgroundColor: "transparent",
		},
		"&.Mui-selected": {
			backgroundColor: "transparent",
			":hover": {
				backgroundColor: "transparent",
			},
			"&.Mui-focused": {
				backgroundColor: "transparent",
			},
		},
		"&.Mui-focused": {
			backgroundColor: "transparent",
			":hover": {
				backgroundColor: "transparent",
			},
		},
	},
}));
