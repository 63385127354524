import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useNavigate } from "react-router-dom";
export default function NotFound() {
	const navigate = useNavigate();
	return (
		<Box
			sx={{
				display: "grid",
			}}
		>
			<Stack
				sx={{
					placeSelf: "center",
				}}
			>
				<Typography variant="h1">404</Typography>
				<Button
					variant="contained"
					color="primary"
					onClick={() => navigate("/")}
				>
					Go Home
				</Button>
			</Stack>
		</Box>
	);
}
