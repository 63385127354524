import React from "react";
import { UserAuth } from "./AuthContext";

const Context = React.createContext();

export const ChatContextProvider = ({ children }) => {
	const { user } = UserAuth();
	const INITIAL_STATE = {
		chatId: "null",
		user: {},
		project: {},
		loading: false,
	};

	const chatReducer = (state, action) => {
		switch (action.type) {
		case "CHANGE_USER":
			return {
				...state,
				chatId: action.payload.project.uid + user.uid,
				user: action.payload.user,
				project: action.payload.project,
			};
		case "LOADING":
			return {
				...state,
				loading: !state.loading,
			};
		case "RESET":
			return INITIAL_STATE;

		default:
			return state;
		}
	};

	function setChat(project, user) {
		if (Object.keys(user).length > 0) {
			dispatch({
				type: "CHANGE_USER",
				payload: { project, user },
			});
		}
	}
	function restartChat() {
		dispatch({
			type: "RESET",
		});
	}

	function loading() {
		dispatch({
			type: "LOADING",
		});
	}

	const [state, dispatch] = React.useReducer(chatReducer, INITIAL_STATE);

	return (
		<Context.Provider
			value={{ data: state, dispatch, setChat, restartChat, loading }}
		>
			{children}
		</Context.Provider>
	);
};

export function ChatContext() {
	return React.useContext(Context);
}
