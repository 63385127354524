import React from "react";
import { getPlatformsHook } from "../hooks/platforms";

const Context = React.createContext();

export function DrawerContextProvider({ children }) {
	const INITIAL_STATE = {
		// Create a new platform state
		platforms: null,
		selectedPlatform: null,
		open: false,
		openNewPlatform: false,
		// Create ticket state
		openRequestDrawer: false,
		openTicketDrawer: false,
		project: null,
		request: null,
		ticket: null,
		// Work in progress
	};

	function reducer(state, action) {
		switch (action.type) {
		case "TOGGLE_DRAWER":
			return {
				...state,
				open: action.payload,
			};
		case "TOGGLE_NEW_DRAWER":
			return {
				...state,
				openNewPlatform: action.payload,
			};
		case "SELECT_PLATFORM":
			return {
				...state,
				selectedPlatform: action.payload,
			};
		case "SET_PLATFORMS":
			return {
				...state,
				platforms: action.payload,
			};
		case "TOGGLE_REQUEST_DRAWER":
			return {
				...state,
				openRequestDrawer: action.payload,
			};
		case "SET_PROJECT_AND_REQUEST":
			return {
				...state,
				project: action.payload.project,
				request: action.payload.request,
			};
		case "TOGGLE_TICKET_DRAWER":
			return {
				...state,
				openTicketDrawer: action.payload,
			};
		case "SET_TICKET":
			return {
				...state,
				ticket: action.payload.ticket,
				project: action.payload.project,
			};
		case "RESTART_STATE":
			return INITIAL_STATE;
		default:
			return state;
		}
	}

	function closeDrawer() {
		dispatch({ type: "TOGGLE_DRAWER", payload: false });
	}

	function openDrawer(platform) {
		dispatch({ type: "TOGGLE_DRAWER", payload: true });
		if (platform) {
			console.log(platform);
			dispatch({ type: "SELECT_PLATFORM", payload: platform });
		}
	}
	function closeNewDrawer() {
		dispatch({ type: "SELECT_PLATFORM", payload: null });
		dispatch({ type: "TOGGLE_NEW_DRAWER", payload: false });
	}

	async function setPlatforms(token) {
		const platforms = await getPlatformsHook(token);
		dispatch({ type: "SET_PLATFORMS", payload: platforms });
	}

	function openNewDrawer() {
		dispatch({ type: "TOGGLE_NEW_DRAWER", payload: true });
	}

	function openRequestDrawer(project, request) {
		dispatch({ type: "TOGGLE_REQUEST_DRAWER", payload: true });
		dispatch({
			type: "SET_PROJECT_AND_REQUEST",
			payload: { project, request },
		});
	}

	function closeRequestDrawer() {
		dispatch({ type: "TOGGLE_REQUEST_DRAWER", payload: false });
		dispatch({
			type: "SET_PROJECT_AND_REQUEST",
			payload: { project: null, request: null },
		});
	}

	function openTicketDrawer(project, ticket) {

		dispatch({ type: "TOGGLE_TICKET_DRAWER", payload: true });
		dispatch({
			type: "SET_TICKET",
			payload: { project, ticket },
		});
	}

	function closeTicketDrawer() {
		dispatch({ type: "TOGGLE_TICKET_DRAWER", payload: false });
		dispatch({
			type: "SET_TICKET",
			payload: { project: null, ticket: null },
		});
	}

	const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);

	return (
		<Context.Provider
			value={{
				drawerState: state,
				openDrawer,
				closeDrawer,
				closeNewDrawer,
				openNewDrawer,
				setPlatforms,
				openRequestDrawer,
				closeRequestDrawer,
				openTicketDrawer,
				closeTicketDrawer,
			}}
		>
			{children}
		</Context.Provider>
	);
}

export default function DrawerContext() {
	return React.useContext(Context);
}
