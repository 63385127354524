import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

export default function UserCardSmall({ photo, displayName, disabled }) {
	return (
		<Stack
			maxWidth="160px"
			direction="row"
			sx={{ alignItems: "center" }}
			spacing={1}
		>
			{!!photo && <Avatar src={photo} variant="avatarSmall" />}
			<Tooltip arrow title={!disabled && displayName} placement="top">
				<Typography noWrap>{displayName}</Typography>
			</Tooltip>
		</Stack>
	);
}
