import React from "react";
import { IconButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import CancelIcon from "@mui/icons-material/Cancel";
import {
	deleteObject,
	getStorage,
	ref as FirebaseStorageRef,
} from "firebase/storage";
import { deleteEvidence } from "../../../hooks/projects";
import { UserAuth } from "../../../Context/AuthContext";

export default function MediaPrevisualizer({ attachments }) {
	const { user } = UserAuth();
	const storage = getStorage();

	async function handleDelete(ref, ticketUuid, projectUuid) {
		console.log("🚀 ~ file: mediaViewer.js:13 ~ handleDelete ~ ref:", ref);
		await deleteEvidence(ref, ticketUuid, projectUuid, user.accessToken);
		const storageRef = FirebaseStorageRef(storage, ref);
		await deleteObject(storageRef).catch((error) => {
			console.log(
				"🚀 ~ file: mediaViewer.js:20 ~ handleDelete ~ error:",
				error
			);
		});
	}

	function Item({ attachment }) {
		const [over, setOver] = React.useState(false);
		return (
			<Stack
				sx={{
					maxWidth: "100%",
					position: "relative",
				}}
				spacing={1}
				onMouseOver={() => setOver(true)}
				onMouseLeave={() => setOver(false)}
			>
				<Avatar
					// sx={{ marginLeft: 1 }}
					variant="CustomImage"
					src={attachment.link}
				/>
				<IconButton
					sx={{
						visibility: over ? "visible" : "hidden",
						minWidth: "100%",
						maxWidth: "100%",
						position: "absolute",
						// marginLeft: 1,
						marginTop: 0,
						borderRadius: 0,
						left: 0,
						right: 0,
						top: 0,
						bottom: 0,
						padding: 0,
						color: "red",
						"& .MuiStack-root": {
							marginTop: 0,
						},
					}}
					onClick={() =>
						handleDelete(
							attachment.attachmentRef,
							attachment.ticketUuid,
							attachment.projectUuid
						)
					}
				>
					<CancelIcon />
				</IconButton>
			</Stack>
		);
	}

	return (
		<Stack
			direction="row"
			sx={{
				minHeight: "200px",
				minWidth: "fit-content",
				maxWidth: "484px",
				overflowX: "auto",
			}}
		>
			{attachments.map((attachment, index) => (
				<Item key={index} attachment={attachment} />
			))}
		</Stack>
	);
}
