// every project has an attribute of tickets and requests, here we
// show the project in an accordion, then we show the requests in a table

// vvvv those aren't a component, only a modified version of the mui components
import { Accordion } from "../../../templates/MuiCustomComponents/CompactAccordion";
import { TreeView } from "../../../templates/MuiCustomComponents/customizedTreeview";

import TreeItem from "@mui/lab/TreeItem";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { enqueueSnackbar } from "notistack";

import RequestsTable from "./requestsTable";
import UserCardSmall from "../../../templates/UserCardSmall";
import { constructLongDate, constructTime } from "../../../hooks/dateCreation";
import { editQuoteReady } from "../../../hooks/projects";
import { UserAuth } from "../../../Context/AuthContext";

// the projects are passed as props from the parent component,
// so we dont need to use context here
export default function RequestsAccordion({ project }) {
	/* im declaring the drawer here to have the same scope as project prop and dont need to use context/props 🫠 */

	const { user } = UserAuth();

	async function handleQuoteReady() {
		if (Object.keys(project?.projectLeader).length === 0) {
			enqueueSnackbar("Project Leader not setted", { variant: "error" });
			return;
		}

		await editQuoteReady(project.uid, true, user.accessToken, project.clientEmail);
	}

	return (
		<Accordion elevation={0} disableGutters={true}>
			<AccordionSummary
				sx={{
					paddingX: "calc(1em + 14px)",
				}}
			>
				<Stack
					direction="row"
					sx={{
						width: "100%",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Stack direction="row" spacing={2}>
						<Typography>Project: {project.projectName}</Typography>
						<Typography>{`creation date: ${constructLongDate(
							project.createdAt?.seconds
						)} at ${constructTime(project.createdAt?.seconds)}`}</Typography>
					</Stack>
					<UserCardSmall
						photo={project.projectLeader?.photoURL}
						displayName={project.projectLeader?.displayName ?? "PL no setted"}
					/>
				</Stack>
			</AccordionSummary>
			<AccordionDetails>
				<TreeView
					defaultCollapseIcon={<ExpandMoreIcon />}
					defaultExpandIcon={<ChevronRightIcon />}
				>
					<TreeItem
						sx={{
							".MuiTreeItem-content": {
								paddingLeft: "calc(2em + 14px)",
								paddingTop: 1,
							},
						}}
						nodeId={project.uid}
						label="Requests"
					>
						<RequestsTable project={project} />
					</TreeItem>
				</TreeView>
				{/*
				<TreeView
					defaultCollapseIcon={<ExpandMoreIcon />}
					defaultExpandIcon={<ChevronRightIcon />}
				>
					<TreeItem
						sx={{
							".MuiTreeItem-content": {
								paddingLeft: "calc(2em + 14px)",
								paddingTop: 1,
							},
						}}
						nodeId={project.uid}
						label="Questionnaire answers"
					>
						<Stack spacing={1}>
							{project?.answers?.map((answer) => (
								<Stack key={answer}>
									<>
										{answer?.question} {answer?.answer}
									</>
								</Stack>
							))}
						</Stack>
					</TreeItem>
				</TreeView>
				*/}
				<Button
					sx={{
						color: "#49807A",
						":hover": {
							backgroundColor: "#E3F0EF",
						},
					}}
					fullWidth
					onClick={handleQuoteReady}
					disabled={project.tickets.length === 0}
				>
					Quote Ready
				</Button>
			</AccordionDetails>
		</Accordion>
	);
}
